@font-face {
  font-family: Inter;
  src: url('../public/fonts/INTER.ttf');
}

:root {
  --p-color: #2b3240;
  --s-color: #313949;
  --t-color: white;
  --a-color: #d6ff00;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter';
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

h2 {
  font-weight: 600;
  font-size: 1.5rem;
  color: white;
}

img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

button {
  cursor: pointer;
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

html,
body {
  overflow: hidden;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Questrial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  margin: 0 auto;
}

body {
  background-color: var(--p-color);
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Ovo je za react Quill zbog poravnanja */
.ql-align-right {
  text-align: right !important;
}

.ql-align-center {
  text-align: center !important;
}

.ql-align-left {
  text-align: left !important;
}

.ql-video {
  display: block;
  margin: 0 auto;
  width: 480px;
  height: 320px;
}

.ql-align-center > img {
  max-width: 800px;
}

/* kalkulator plugin */
.css-1txu9g8,
.css-1iypx3a,
.css-9iwzto,
.css-166hzw5,
.css-1uzr0i {
  font-size: 15px !important;
  height: 14.3% !important;
  font-weight: bolder;
}

.css-1lqzgzw {
  font-size: 22px !important;
}

.css-1uzr0i,
.css-9iwzto,
.css-1uzr0i,
.css-1uzr0i,
.css-9iwzto {
  background: orangered !important;
  color: black !important;
  font-weight: bolder;
}

.css-1txu9g8:first-of-type {
  background: green !important;
}

.css-hayew9 {
  color: orangered !important;
  font-weight: bolder;
  height: 28% !important;
}

.css-1iypx3a,
.css-166hzw5 {
  background: #323232 !important;
}

.css-1iypx3a {
  background: #434343 !important;
}

.css-h2okw {
  height: 100% !important;
}

.ant-modal-title {
  text-align: center;
}

.crimsonBtn,
.redBtn,
.orangeBtn,
.lightgreenBtn,
.greenBtn {
  color: white !important;
}

.crimsonBtn {
  background-color: crimson !important;
}

.redBtn {
  background-color: red !important;
}

.orangeBtn {
  background-color: orange !important;
  color: black !important;
}

.lightgreenBtn {
  background-color: #9bfa20 !important;
  color: black !important;
}

.greenBtn {
  background-color: green !important;
}

.ant-modal-wrap {
  z-index: 3000 !important;
}

/* .ant-modal-content {
  background-image: linear-gradient(
    to bottom right,
    #111219,
    #40445e
  ) !important;

  box-shadow: 0px 4px 4px 0px #00000040 !important;
} */

.ant-modal {
  background-image: linear-gradient(
    to bottom right,
    #40445e,
    #111219
  ) !important;
  background-color: transparent !important;
  padding-bottom: 0px;
}
.ant-modal-content {
  background-color: transparent !important;
  box-shadow: none !important;
  color: white;
}
.mobileModal .ant-modal-content {
  background-color: #353945 !important;
  border: 1px solid #212631 !important;
  padding: 10px !important;
}
.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-close {
  color: white !important;
}
.ant-notification .ant-notification-notice-wrapper {
  background-color: transparent;
}
.ant-notification-topRight.ant-notification-stack
  > .ant-notification-notice-wrapper {
  top: 100px;
}
.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-message {
  font-size: 18px;
}
.ant-form label {
  color: white !important;
}
.ant-radio-button-wrapper span {
  color: black !important;
}
.ant-picker-footer a {
  color: #000000e0;
}
.swiper-pagination {
  position: absolute !important;
  top: 8px !important;
  right: 16px !important;
  left: auto !important;
  width: auto !important;
  display: flex;
  justify-content: flex-end;
}
.swiper-slide {
  width: 100% !important;
}
#tipsterAwards > .sideImagesTipster {
  padding-top: 0px !important;
}
