.successPageContianer * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.successPageContianer  {
  display: flex;
  flex-direction: column;
  text-align: start;
  justify-content: start;
  background-image:linear-gradient(to top left, #111219, #40445e); 
  border: 1px solid #212631;
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  max-width: 526px;
  margin: 0 auto;
  margin-top: 40px;
}

.iconWrapper{
  width: 44px;
  height: 44px;

}

.successPageContianer h2 {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 600;
  margin-top: 24px;
}


.successPageContianer p {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 400;
  text-align: start;
  margin-top: 24px;
}
.successPageContianer p:last-child{
  margin-top: 0px;

}
.successPageContianer button { 
    background-color: #D6FF00;
    padding: 12px 24px;
    border-radius: 6px;
    width: 100%;
    margin-top: 24px;
    color: #252F3B;
    font-size: 16px;
    font-weight: 500;
}
