.backButton {
  width: auto;
  padding: 8px 16px 8px 12px;
  background-color: #ffffff29;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-weight: 500;
  position: absolute;
  left: 0;
  top: -75px;
}

.backButton > img {
  width: 20px;
  height: 20px;
}

.contentContainer {
  width: 100%;
  height: auto;
  background-color: #353945;
  border: 1px solid #212631;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 12px;
  color: white;
  padding: 16px;
}

.topButtonsContainer {
  display: flex;
  width: 100%;
  gap: 16px;
}

.topButtons {
  display: flex;
  flex: 1;
  gap: 12px;
  flex-direction: column;
}

.topButtons button {
  display: flex;
  gap: 5px;
  width: 100%;
  min-height: 48px;
  font-size: 14px;
  align-items: center;
  justify-content: flex-start;

  background-color: #2b3240;
  border: 1px solid #212631;
  border-radius: 8px;
  box-sizing: border-box;
  color: white;
  padding: 6px 12px;
}

.topButtons button img {
  width: 36px;
}

.tipsterHeader {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  color: var(--a-color);
  font-weight: 600;
}

.tipsterHeader img {
  width: 15px;
}

.tipsterMatchContainer {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  gap: 10px;
}

.tipsterMatchContainer .headerSelection {
  background-color: #ffffff29;
  color: var(--a-color);
  border-radius: 8px;
  text-align: center;
  padding: 2px 0;
}

.tipsterMatchContainer span {
  color: var(--a-color);
  min-width: 167px;
}

.confirmContainer {
  display: flex;
  width: 100%;
  gap: 25px;
  justify-content: space-between;
}

.confirmContainer button {
  flex: 1;
  background-color: #d6ff00;
  border: 1px solid #b8db00;
  padding: 10px 16px;
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 8px;
  color: #252f3b;
}

.confirmContainer button img {
  width: 14px;
}

.confirmContainer span {
  flex: 1;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.mailNotificationCheckbox {
  display: flex;
  width: 100%;
}

.mailNotificationCheckbox label {
  font-size: 12px;
  color: #ffffffd1;
}

.mailNotificationCheckbox > input[type='checkbox'] {
  display: block;
  width: 13px;
  aspect-ratio: 1;
  background-color: #fff;
  color: #000;
}

.menuButton {
  display: none !important;
}

.menuButton img {
  width: 15px !important;
  transform: rotate(180deg);
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
    hue-rotate(288deg) brightness(102%) contrast(102%);
}

.menuButton .submenuActive {
  transform: rotate(0deg);
  filter: brightness(0) saturate(100%) invert(0%) sepia(81%) saturate(7464%)
    hue-rotate(184deg) brightness(82%) contrast(90%);
}

.menuButton button {
  justify-content: space-between !important;
}

.activeButton {
  background-color: var(--a-color) !important;
  color: black !important;
}

@media (max-width: 800px) {
  .backButton {
    position: relative;
    width: 95%;
    top: -0.8rem;
  }
}

@media (max-width: 768px) {
  .topButtonsContainer {
    flex-direction: column;
  }

  .tipsterHeader {
    font-size: 17px;
  }

  .menuButton {
    display: flex !important;
  }
}
