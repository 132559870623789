.mainContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  height: 100dvh;
}

.headerContainer {
  text-align: center;
  margin: 10px auto 0;
  width: 100%;
  max-width: 1200px;
  border-top: 2px solid #d6ff00;
  border-bottom: 2px solid #d6ff00;
  padding: 10px 16px 10px;
  box-sizing: border-box;
}

.header {
  color: #d6ff00;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 0px !important;
}
.contentContainer {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.buttonContainer {
  display: flex;
  gap: 4px;
  padding-bottom: 4px;
  width: 100%;
  height: 42px;
  border: 1px solid #212631;
  border-radius: 8px;
  background-color: #262c39;
}

.resultsButton,
.liveStreamButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  gap: 4px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.activeButton {
  background-color: #d6ff00;
  color: #1d2939;
}

.inactiveButton {
  background-color: #1d2939;
  color: #ffffff;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #1d2939;
}
.inactiveDot{
  filter: brightness(0) saturate(100%) invert(94%) sepia(62%) saturate(2%) hue-rotate(319deg) brightness(107%) contrast(101%);
}
.activeLiveStream{
  filter: brightness(0) saturate(100%) invert(11%) sepia(9%) saturate(3600%) hue-rotate(175deg) brightness(90%) contrast(86%);
}
.inactiveLiveStream{
  filter: brightness(0) saturate(100%) invert(94%) sepia(62%) saturate(2%) hue-rotate(319deg) brightness(107%) contrast(101%);
}
.liveIcon {
  width: 22px;
  height: 22px;
 
}

.scoreBatMainContainer {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  overflow: scroll;
  height: calc(100dvh - 340px);
  margin-top: 16px;
}
iframe {
  width: 100%;
  height: 100%;
  overflow: scroll;
}
.commentButton {
  width: 100%;
  height: 100%;
  max-height: 44 dvh;
  background: #d6ff00;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border: 1px sold #b8db00;
  border-radius: 6px;
  padding: 8px 24px;
  cursor: pointer;
}
.commentsContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 60px;
  left: 0;
  width: 100%;
  height: 0%;
  max-height: calc(100dvh - 60px);
  background: #2b3240;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.3);
  border-radius: 16px 16px 0 0;
  padding: 0px;
  overflow-y: auto;
  transition: height 0.3s ease-in-out, padding 0.3s ease-in-out;
  z-index: 100;
}

.commentsContainer.openComments {
  /* display: block; */
  /* bottom: 0; */
  height: 100%;
  padding: 16px 16px 0 16px;
}

.closeButton {
  align-self: flex-end;
  background: #d6ff00;
  color: #000;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px 12px;
  margin-bottom: 10px;
}

.commentButton {
  flex-shrink: 0;
  width: 100%;
  height: 48px;
  margin-top: 20px;
  background: #d6ff00;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 100px;
}
@media (min-width: 1024px) {
  .mainContainer {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 100dvh !important;
  }

  .headerContainer {
    text-align: center;
    padding-top: 24px;
  }

  .contentContainer {
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 24px;
    height: calc(100vh - 150px);
  }
  .contentHolder {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 801px;
    height: 100%;
  }
  .buttonContainer {
    display: flex;
    gap: 4px;
    margin-right: 24px;
    width: 801px;
    padding: 4px;

    margin-top: 24px;
  }

  .resultsButton,
  .liveStreamButton {
    font-size: 16px;
    gap: 4px;
    padding: 6px;
    width: 394px;
    height: 34px;
  }

  .scoreBatMainContainer {
    width: 801px;
    display: flex;
    flex-direction: column;
    height: 1497px;
    justify-content: start;
    align-items: start;
    overflow: scroll;
    margin-bottom: 100px;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  .commentsContainer {
    position: static;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    max-width: 375px;
    width: 100%;
    height: 100%;
    padding: 24px 0;
    box-shadow: none;
    background: none;
    border-radius: 0;
    z-index: 10;
  }

  .commentButton {
    display: none;
  }
  .closeButton {
    display: none;
  }
}
