shopHomePage * {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.productContainer {
  padding: 24px 10px 16px 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.headerWrapper {
  padding: 0px 16px;
}
.contactContainer {
  padding: 16px;

  width: 100%;

  margin: 0 auto;
}
.textContainer {
  border: 1px solid #212631;
  background-color: #262c39;
  border-radius: 8px;
  padding: 6px;
}
.textContainer > p {
  font-size: 14px;
  font-weight: 500;
  color: #ffffffd1;
}
.break {
  display: none;
}
.textContainer > button {
  background-color: #d6ff00;
  border: 1px solid #b8db00;
  padding: 12px 24px;
  border-radius: 6px;
  color: #252f3b;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  margin-top: 16px;
}
.sectionMenu {
  padding: 24px 16px;
  width: 100%;
  margin: 0 auto;
}
.shopSection {
  margin-bottom: 24px;
}
.shopSection:last-child {
  margin-bottom: 0px;
}
.shopSectionHeader {
  display: flex;

  gap: 8px;
}

.shopSectionHeader > h2 {
  color: #d6ff00;
  font-size: 16px;
  font-weight: 500;
}

.shopSectionHeader > img {
  width: 22px !important;
  height: 22px !important;
}
.sectionButtonsWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
  margin-bottom: 16px;
}
.sectionButtonsWrapper > button {
  padding: 6px 24px;
  border-radius: 6px;
  background-color: #262c39;
  border: 1px solid #212631;
  color: #ffffff;
  font-weight: 500;
  font-size: 13px;
}
.headerNavigation {
  background-color: #262c39;
  border: 1px solid #212631;
  padding: 4px;
  border-radius: 8px;
  display: flex;
  gap: 4px;
  width: 100%;
  justify-content: space-between;

  margin-top: 24px;
}
.navItem {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
  cursor: pointer;
  margin: 0 auto;
  width: 100%;
}
.active {
  background-color: #d6ff00 !important;
  color: #1d2939 !important;
  border-radius: 6px;
}
.active img {
  filter: brightness(0) saturate(100%) invert(11%) sepia(13%) saturate(2254%)
    hue-rotate(174deg) brightness(91%) contrast(87%);
}
.navItem > p {
  font-size: 16px;
  font-weight: 500;
}
.navItem > img {
  width: 22px !important;
  height: 22px !important;
}
.navItem.selected {
  background-color: #d6ff00;
  color: #1d2939;
  border-radius: 6px;
}

.navItem.selected img {
  color: #1d2939 !important;
}
.searchContainer {
  margin-top: 16px;
}
.searchContainer > input {
  margin-top: 16px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #262c39;
  border: 1px solid #212631;
  color: #fff;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
}

.inputWrapper {
  position: relative;
}
.inputWrapper > img {
  width: 20px !important;
  height: 20px !important;
}
.searchInput {
  width: 100%;
  padding: 10px 10px 10px 40px;
  border-radius: 5px;
  font-size: 16px;
  background-color: #262c39;
  border: 1px solid #212631;
  color: #fff;
  font-weight: 500;
}

.searchIcon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);

  cursor: pointer;
}

@media (min-width: 1024px) {
  .right {
    display: flex;
    padding-left: 16px;
  }
  .headerWrapper {
    display: flex;
    flex-direction: column;
    padding: 0px;
  }
  .headerNavigation {
    max-width: 856px;
  }
  .navItem {
    flex-direction: row;
    gap: 6px;
  }
  .searchContainer {
    max-width: 856px;
  }
  .productContainer {
    max-width: 856px;
    justify-content: space-between;
    padding-left: 62px;
    padding-right: 36px;
  }
  .contactContainer {
    width: 100%;
    max-width: 856px;
    margin: 0;
  }
  .sectionMenu {
    max-width: 320px;
  }
  .desktopWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px;
  }
  .textContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .break {
    display: block;
  }
  .textContainer > button {
    max-width: 223px;
    margin-top: 0px;
  }
}
