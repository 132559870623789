
.pageContainer{
    background-color: #353945;
    padding: 12px;
    width: 100%;
    border: 1px solid #212631;
    border-radius: 12px;
}
.backButton{
    background-color: #FFFFFF29;
    border: 1px solid #D0D5DD;
    padding: 8px 16px 8px 12px;
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    justify-content: center;
    gap: 6px;
    font-size: 16px;
    font-weight: 500;
}
.backButton > img{
    width: 20px;
    height: 20px;
}
.titleButton {
    width: 100%;
    color: white;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    display: flex;
    flex-direction: column;
    border: none;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .toggleIcon {
    width: 20px;
    height: 20px;
  }
  

  
  .textWrapper {
    background-color: transparent;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    
  }
  .textWrapper > p{
    color: #FFFFFFD1;
    font-size: 14px;
    font-weight: 400;
  }
  .active {
    border: 1px solid #D6FF00 !important; 
    border-radius: 8px;
    color: #D6FF00 !important; 
  }
  
  .active .textWrapper {
    opacity: 1;
    max-height: 100%;
    
  }
  
.titleButton{
    background-color: #2B3240;
    border: 1px solid #212631;
    padding: 12px 16px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    margin: 0 auto;
    margin-top: 16px;
    width: 90%;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    
}

.divider{
    border: 1px solid #D6FF00;
    width: 100%;
    margin: 16px 0px;
}

@media(min-width:1024px) {
    .pageContainer{
        padding: 0px;
    }
    .backButton{
        display: none;
    }
}