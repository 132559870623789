.productPreview * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.productPreview {
  display: flex;
  gap: 1rem;
  font-weight: 700;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  text-align: center;
  margin-top: 24px;
}
.productImageWrapperDesktop {
  display: none;
}
.productInfo {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 5px;

  flex: 1;
  height: 100%;
  overflow: auto;
}

.productTitle {
  color: #ffffff;
  text-transform: lowercase;
  font-size: 20px;
  font-weight: 500;
  flex-shrink: 0;
}

.productDescription {
  font-size: 1rem;
  padding: 0.2rem;
  text-align: start;
  white-space: pre-line;
}

.productPrice {
  color: #022161;
  font-size: 1.5rem;
  text-align: center;
}

.productStock {
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  border-radius: 0.5rem;
}
.onStock {
  background-color: #0000005c;
  border: 1px solid #b8db00;
  padding: 4px 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.outOfStock{
  background-color: #0000005c;
  border: 1px solid #D92D20;
  padding: 4px 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.outOfStock > img {
  filter: brightness(0) saturate(100%) invert(26%) sepia(90%) saturate(2004%) hue-rotate(345deg) brightness(85%) contrast(102%);
}
.onStock > img {
  width: 20px;
  height: 20px;
}

.auctionExpireDate,
.productCategory,
.tourDate {
  color: #0b0b25;
  font-weight: 700;
}

.productDescriptionContainer {
  display: inline;
  margin-block: 1rem;
}
.productDescription {
  color: #ffffffd1;
  font-size: 14px;
  font-weight: 500;
  text-transform: lowercase;
}
.seeMoreBtn {
  cursor: pointer;
  background-color: #0000005c;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #b8db00;
  border-radius: 8px;
  padding: 6px;
  color: #fff;
  margin-top: 4px;
}

.attributesTable {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid #212631;
}

.attributesTable thead {
  background-color: #d6ff00;
}
.attributesTable tbody {
  background-color: #262c39;
}
.attributesTable th {
  padding: 6px 16px;
  color: #252f3b;
  font-weight: 500;
}

.attributesTable td {
  padding: 12px;
  color: white;
  border: 1px solid #212631;
}

.attributeCell {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.valueCell {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.featuredProductsMobile {
  display: none;
}

.productImageWrapperMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-shrink: 0;
  cursor: pointer;
  cursor: zoom-in;
  margin-bottom: 16px;
}
.productImageWrapperMobile > img {
  width: 343px;
  height: auto;
}
.attributesForm {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.formQuantityItem {
  background-color: #262c39;
  border: 1px solid #212631;
  color: white;
  border-radius: 8px;
  display: flex;
  padding: 6px 6px 6px 10px;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
}

.atrQuantityField {
  color: black;
  border-radius: 0.5rem;
  padding: 0.1rem 0.5rem;
}

.formQuantityItem input {
  width: 40px;
  height: 100%;
  font-size: 1.1rem;
  padding: 0.125rem;
  border: 1px solid #212631;
  outline: none;
  text-align: center;
}
.formDescItem textarea {
  width: 100%;
  min-width: 343px;
  height: 104px;
  border-radius: 12px;
  padding: 12px;
  border: none;
  background-color: #262c39;
  border: 1px solid #212631;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  padding-top: 0px;
}

.formDescItem textarea::placeholder {
  text-align: start;
  color: #ffffff;
  font-weight: 500;
  white-space: pre-line;
}
.cartContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.addToCartBtn {
  color: #252f3b;
  padding: 12px 24px !important;
  font-size: 16px;
  font-weight: 500;
  background-color: #d6ff00;
  border-radius: 0.5rem;
  border: 1px solid #b8db00;
  box-shadow: 0px 0px 8px 0px #d6ff003d;
}
.productPrice > span {
  font-size: 24px;
  font-weight: 700;
  background-image: linear-gradient(#f1f0f3, #737175);
  color: transparent;
  background-clip: text;
}
@media (min-width: 1024px) {
  .productPreview {
    height: 100%;
    flex-direction: row;
    margin-top: 0px;
  }

  .productInfo {
    flex-direction: column;
    width: 400px;
    text-align: center;
  }

  .attributesTable {
    margin-bottom: 16px;
  }

  .productsHeader h3 {
    font-size: 2rem;
  }
  .productImageWrapperDesktop {
    display: block;
    width: 200px;
    height: 200px;
    margin-left: 12px;
  }
  .productImageWrapperDesktop > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    background-color: #ffff;
    border-radius: 12px;
    
  }

  .productImageWrapperMobile {
    display: none;
  }
}
