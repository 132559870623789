.packagesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.singleProductCard {
  width: 144px;
  padding: 24px 8px;
  background: linear-gradient(317.13deg, #111219 10%, #40445e 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  border: 1px solid;
  height: 260px;
  border: 1px solid;
  border-image: linear-gradient(#ffffff29, #111219);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.singleProductCard > h3 {
  color: #d6ff00;
  font-size: 16px;
  font-weight: 600;
}
.price {
  background-image: linear-gradient(#f1f0f3, #737175);
  color: transparent;
  background-clip: text;
  font-size: 32px;
  font-weight: 700;
}
.singleProductCard > span {
  color: #ffffffd1;
  font-size: 14px;
  font-weight: 500;
}
.divider {
  width: 100%;
  margin: 12px 0px;
  border: 1px solid #ffffff1f;
}
.discount {
  color: #d6ff00;
  font-size: 14px;
  font-weight: 500;
}
.calculatedPrice {
  color: #ffffffd1;
  text-wrap: nowrap;
  font-size: 11px;
  font-weight: 500;
}
@media (min-width: 765px) {
  .packagesContainer {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
  }
  .singleProductCard {
    width: 196px;
  }
  .calculatedPrice{
    font-size: 14px;
  }
}
