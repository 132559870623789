.pageContainer * {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
}

.categoriesAndProducts {
  display: flex;
  gap: 5px;
  min-height: 360px;
  overflow: hidden;
}
.headerWrapper {
  padding: 0px 16px;
}
.navigation button {
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}
.sectionMenu {
  display: none;
}
.sortBtnMobile {
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  background-color: #ffffff29;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  text-wrap: nowrap;
  margin-top: 24px;
  padding: 8px 12px;
}
.sortBtnDesktop {
  display: none;
}
.mobileModal, .ant-modal ,  .ant-modal-content {
  background-color: #353945 !important;
  

}
.mobileCategories{
  border: none;
}
.carets {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.caretDown,
.caretUp {
  color: #ff0000;
  height: 20px;
  width: 20px;
  transform: scaleY(2.5);
}

.caretUp {
  color: #b2ec0a;
  margin-left: -0.5rem;
}
.ant-modal-close {
  color: #fff !important;
}
.modalWrapper {
  width: fit-content !important;
  height: fit-content !important;
  max-width: 100vw;
  max-height: 100vh;
}

.imagePreviewWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex: 1;
}

.noResults {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: white;
}

.spinner {
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 60px;
}

.backFromProd {
  display: none;
}
.buttonContainer {
  padding: 0px 16px;
  min-width: 343px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.categoryButton {
  background-color: #d6ff00;
  color: #252f3b;
  padding: 8px 12px;
  border: 1px solid #b8db00;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 24px;
}
.categoryButton > img {
  width: 20px;
  height: 20px;
}
.productsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  gap: 10px;
}
.porductsHeader {
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  margin-top: 24px;
  padding: 0px 16px;
}
@media (min-width: 1024px) {
  .wraperContainer {
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
  }

  .headerNavigation {
    max-width: 856px;
    display: flex;
  }
  .headerWrapper {
    display: flex;
    gap: 24px;
    height: 104px;
  }
  .desktopHolder {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 864px;
  }
  .navItem {
    flex-direction: row !important;
    gap: 6px;
  }

  .searchContainer {
    max-width: 856px;
    display: flex;
    align-items: center;
    gap: 24px;
  }
  .searchInput {
    width: 622px !important;
  }
  .sortBtnDesktop {
    display: block;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    background-color: #ffffff29;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    text-wrap: nowrap;

    padding: 8px 12px;
  }
  .sortBtnMobile {
    display: none;
  }
  .sectionMenu {
    display: block;
    max-width: 320px;
    width: 100%;
    margin-top: 24px;
  }
  .contentContainer {
    display: flex;
    align-items: start;
    gap: 24px;
  }
  .shopSection:last-child {
    margin-bottom: 0px;
  }
  .shopSectionHeader {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .shopSectionHeader > h2 {
    color: #d6ff00;
    font-size: 16px;
    font-weight: 500;
  }
  .shopSectionHeader > img {
    width: 22px !important;
    height: 22px !important;
  }
  .sectionButtonsWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;
    margin-bottom: 16px;
  }
  .sectionButtonsWrapper > button {
    padding: 6px 24px;
    border-radius: 6px;
    background-color: #262c39;
    border: 1px solid #212631;
    color: #ffffff;
    font-weight: 500;
    font-size: 13px;
  }
  .porductsHeader {
    display: none;
  }
  .productsContainer {
    max-width: 856px;
    justify-content: flex-start;
    padding-left: 16px;
    margin-top: 0px;
  }
  .desktopWrapper {
    display: flex;
    align-items: start;
    max-width: 843px;
    padding-left: 16px;
    margin-top: 24px;
    padding-top: 24px;
  }

  .noResults {
    font-size: 3rem;
    color: black;
  }

  .productContainerMobile {
    display: none;
  }

  .navigatePrev,
  .navigateNext {
    flex-shrink: 0;
    height: 70px;
    width: 50px;
    background-color: white;
    height: 100%;
    top: 0;
  }

  .navigatePrev svg,
  .navigateNext svg {
    transform: scale(1.8);
    cursor: pointer;
  }

  .spinner {
    margin-top: 120px;
  }
}
.headerNavigation {
  background-color: #262c39;
  border: 1px solid #212631;
  padding: 4px;
  border-radius: 8px;
  display: flex;
  gap: 4px;
  width: 100%;
  justify-content: space-between;

  margin-top: 24px;
}
.navItem {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
  cursor: pointer;
  margin: 0 auto;
  width: 100%;
}
.navItem > p {
  font-size: 16px;
  font-weight: 500;
}
.navItem > img {
  width: 22px !important;
  height: 22px !important;
}
.navItem.selected {
  background-color: #d6ff00;
  color: #1d2939;
  border-radius: 6px;
}

.navItem.selected img {
  color: #1d2939 !important;
}
.active {
  background-color: #d6ff00 !important;
  color: #1d2939 !important;
  border-radius: 6px;
}
.active img {
  filter: brightness(0) saturate(100%) invert(11%) sepia(13%) saturate(2254%)
    hue-rotate(174deg) brightness(91%) contrast(87%);
}
.searchContainer {
  margin-top: 16px;
}
.searchContainer > input {
  margin-top: 16px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #262c39;
  border: 1px solid #212631;
  color: #fff;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
}

.inputWrapper {
  position: relative;
}
.inputWrapper > img {
  width: 20px !important;
  height: 20px !important;
}
.searchInput {
  width: 100%;
  padding: 10px 10px 10px 40px;
  border-radius: 5px;
  font-size: 16px;
  background-color: #262c39;
  border: 1px solid #212631;
  color: #fff;
  font-weight: 500;
}

.searchIcon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);

  cursor: pointer;
}
.spinner {
  aspect-ratio: 1;
  width: 100px;
  margin: 0 auto;
  margin-top: 60px;
}
