.container {
  display: flex;
  gap: 5px;
  /* max-width: 1200px; */
  width: 100%;
  height: calc(100vh - 172px);
  justify-content: space-between;
  margin: 0 auto;
  overflow: hidden;
  background-color: white;
  background-color: var(--p-color);
  /* flex-grow: 1; */
}

.outerContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100dvh;
  width: 100%;
  overflow: hidden !important;
}

.noNewsBar {
  height: calc(100vh - 65px) !important;
}

.containerInner {
  display: flex;
  flex: 4 1;
  flex-direction: column;
  overflow-y: hidden;
  width: 100%;
}

.sideImage {
  flex: 1 4;
  /* max-width: 18vw; */
  overflow-y: scroll;
  overflow-x: hidden;
}

.sideImage:first-child {
  margin-left: 10px;
}

.sideImage:last-child {
  margin-right: 10px;
}

.container::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

@media (max-width: 768px) {
  .container,
  .outerContainer {
    overflow-y: hidden;
  }
}

@media screen and (max-width: 1280px) {
  .container {
    flex-direction: column;
    overflow-y: auto;
  }

  .containerInner {
    flex-direction: column;
    height: auto;
    overflow-y: auto;
    max-width: 100%;
    margin: 0;
  }
  .sideImage {
    display: none;
  }
}
