.mainCenter {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  overflow: scroll;
}

.leftPanel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 0;
}

.rightPanel {
  border-radius: 8px;

  padding: 24px 16px;
}

.content {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 28px;
  text-align: center;
  color: #d6ff00;

  padding: 10px;
  border-top: 2px solid #d6ff00;
  font-weight: 600;
  border-bottom: 2px solid #d6ff00;
}

.itemText {
  font-size: 16px;
  text-align: start;
  color: #fff;
}
.chaptertitle {
  font-size: 20px;
  text-align: center;
  color: #fff;
  border-radius: 15px;
  padding: 12px;
}

.contentRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  text-align: center;
  margin-top: 16px;
}

.itemImage {
  width: 100%;
  max-height: 300px;

  border-radius: 12px;
  margin: 20px 0;
}

.itemImageATag {
  display: block;
  width: 100%;
  text-align: center;
}

@media (min-width: 768px) {
  .leftPanel {
    width: 100%;
    z-index: 10;
    order: 1;
  }

  .rightPanel {
    order: 2;
  }
}

@media (min-width: 1024px) {
  .mainCenter {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 25px;
    padding: 20px;
  }
  .leftPanel {
    order: 1;
  }
  .rightPanel {
    order: 2;
  }
}
