.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.spinner > div {
  height: auto !important;
  padding: 1rem;
}

.componentHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.componentHeader .leftButtons {
  display: flex;
  gap: 10px;
}

.componentHeader .infoButton {
  padding: 8px 16px 8px 12px;
  background-color: #ffffff29;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-weight: 500;
}

.tablePeriodPicker {
  padding: 8px 16px 8px 12px !important;
  background-color: #ffffff29 !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
  color: #fff !important;
  display: flex !important;
  gap: 6px;
  font-weight: 500 !important;
  cursor: pointer;
}

.tablePeriodPicker input {
  background-color: transparent !important;
  border: none !important;
  color: #fff !important;
  font-weight: 500;
}

.tablePeriodPicker .ant-picker-dropdown {
  background: #222;
  border-radius: 8px;
}

.tablePeriodPicker input::placeholder,
.tablePeriodPicker svg {
  color: #ffffffd4 !important;
  filter: brightness(0) saturate(100%) invert(99%) sepia(22%) saturate(2%)
    hue-rotate(228deg) brightness(108%) contrast(101%) !important;
}

.componentHeader .searchBar {
  background-color: #212631;
  border: 1px solid #212631;
  border-radius: 8px;
  padding: 8 16px;
  display: flex;
}

.componentHeader .searchBar > button {
  background-color: transparent;
  border: none;
  margin-left: 5px;
}

.componentHeader .searchBar > input {
  background-color: transparent;
  border: none;
  color: white;
}

.componentHeader .searchBar > input:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

.tableHeader {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.tableHeader h3 {
  font-size: 18px;
  color: #d6ff00;
  font-weight: 600;
}

.tableHeader .tableHeaderTotalScore {
  position: absolute;
  right: 13px;
  top: 5px;
  font-size: 12px;
  color: white;
}

.tableBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
}

.tableItem {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  border: 1px solid #212631;
}

.tableItem:nth-last-of-type(1) {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.tableItem:nth-child(even) {
  background-color: #212631;
}

.tableItem:nth-child(odd) {
  background-color: #41434b;
}

.tableItem .tableInfo {
  display: flex;
  gap: 10px;
}

.tableItem .tableInfo img {
  width: 44px;
  aspect-ratio: 1;
}

.tableItemRank img {
  width: 20px !important;
  aspect-ratio: 1;
}

.tableItemRank,
.currentUserTableItem .tableItemTotalScore,
.tableItemTotalScore {
  aspect-ratio: 1;
  min-width: 44px;
  background-color: #edeff31f;
  border: 1px solid #ced3de;
  color: #ced3de;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
}

.tableItemPlayer {
  color: #ffffff;
  font-size: 14px;
  margin: auto 0;
}

.tableItemTotalScore {
  color: white;
  font-size: 14px;
  margin: auto 0;
  border: none;
  background-color: transparent;
}

.currentUserTableItem {
  border: 1px solid #212631;
  border-bottom: 0;
  background-color: #d6ff00 !important;
}

.currentUserTableItem .tableItemPlayer {
  color: black;
}

.currentUserTableItem .tableItemRank,
.currentUserTableItem .tableItemTotalScore {
  background-color: #262c39;
}

.pagination {
  margin-top: 1rem;
  padding: 0 1rem;
}

.noData {
  color: white;
}

.tableContainer {
  border-radius: 8px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .componentHeader {
    flex-direction: column-reverse;
    gap: 1rem;
    padding: 0 10px;
  }

  .componentHeader .leftButtons {
    width: 100%;
  }

  .componentHeader .leftButtons button,
  .componentHeader .leftButtons .tablePeriodPicker {
    flex: 1;
  }

  .searchBar {
    padding: 8px;
  }

  .searchBar img {
    width: 20px;
  }

  .pagination {
    padding: 0 2px;
  }
}
