.newsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  height: 52px;
  background-color: #1a1a1a;
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  position: relative;
  z-index: 50;
}

.newsContent {
  border: #0a0d23;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  padding: 10px 0 10px 0;
  position: relative;
  animation: scroll linear infinite;
}

.newsBtnWrapper {
  position: relative;
  background-color: #1a1a1a;
  display: flex;
  align-items: center;
  z-index: 10;
  padding: 0.5rem;
  padding: 0.75rem;
  padding-left: 1rem;
  font-size: 14px;
}

.newsBtnWrapper button {
  background-color: #353945;
  color: white;
  padding: 4px 8px 4px 6px;
  border-radius: 6px;
  font-size: 14px;
  border: 1px solid #b8db009e;
  display: flex;
  gap: 4px;
}

.newsBtnWrapper button img {
  width: 18px;
  aspect-ratio: 1;
}

.newsItem {
  display: flex;
  align-items: center;
}

.newsItem span {
  font-family: 'Oxanium', sans-serif;
}

.redDotWrapper {
  fill: #d92d20;
  color: #d92d20;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  height: 100%;
}
.redDotWrapper img {
  height: 8px;
  width: 8px;
}

.seperatorLine {
  height: 26px;
  background-color: #b8db009e;
  width: 1px;
  position: absolute;
  right: 0;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
