.infoContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #d6ff00;
  border-radius: 0.5rem;
  padding: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;
}

.infoContainer span,
.infoContainer h2 {
  color: #2b3240;
  text-align: center;
  padding-bottom: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.infoContainer h2 {
  color: #2b3240;
}

@media (max-width: 786px) {
  .infoContainer {
    margin: 0 5px 1rem 5px;
  }

  .infoContainer span,
  .infoContainer h2 {
    padding-left: 5px;
    padding-right: 5px;
  }
}
