.mainContainer {
  width: 100%;
  height: 100dvh;
  overflow: auto;
}
.headerContainer h1 {
  color: #d6ff00;
  font-size: 28px;
  font-weight: 600;
  border-top: 2px solid #d6ff00;
  border-bottom: 2px solid #d6ff00;
  padding: 10px;
  position: relative;
  text-align: center;
}
.backButtonDesktop {
  display: none;
  margin-left: 1rem;
}
.backButtonMobile {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #ffffff29;
  border: 1px solid #d0d5dd;
  padding: 8px 16px 8px 12px;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin: 0 auto;
  margin-top: 16px;
  width: 90%;
  justify-content: center;
}
.backButtonMobile > img {
  width: 20px;
  height: 20px;
}
.contentContainer {
  text-align: center;
  margin-top: 16px;
  padding: 0px 16px;
  color: #fff;
  background-color: #353945;
  border: 1px solid #212631;
  margin: 16px 12px;
  border-radius: 8px;
}
.contentContainer > p:first-child {
  margin-top: 16px;
}
.contentHeader {
  font-size: 24px;
  font-weight: 600;
  color: #d6ff00;
  text-align: center;
  margin-top: 16px;
}
.green {
  color: #d6ff00;
  font-size: 18px;
  margin: 12px 0px;
}
.dropdownButton{
  background-color: #2B3240;
  border: 1px solid #212631;
  padding: 12px 16px;
  color: #fff;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  text-align: start;
  margin-top: 16px;
}

.titleWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.active {
  border: 1px solid #D6FF00 !important; 
  border-radius: 8px;
  color: #D6FF00 !important; 
}
.toggleIcon{
  width: 18px;
  height: 18px;
}
.divider {
   border: 1px solid #D6FF00;
    width: 100%;
    margin: 16px 0px;
}
.textWrapper{
  color: #fff;
}
@media (min-width: 1024px) {
  .backButtonDesktop {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #ffffff29;
    border: 1px solid #d0d5dd;
    padding: 8px 16px 8px 12px;
    border-radius: 8px;
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    left: 0;
  }
  .backButtonMobile {
    display: none;
  }
  .contentContainer{
    text-align: start;
  }
}
