.mainContainer {
  padding: 16px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  overflow: auto;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}
.pageHeader {
  width: 100%;
}
.pageHeader h1 {
  text-align: center;
  color: #d6ff00;
  font-size: 24px;
  font-weight: 600;
  border-top: 2px solid #d6ff00;
  border-bottom: 2px solid #d6ff00;
  padding: 10px;
  width: 100%;
}
.backButtonMobile {
  display: flex;
  align-items: center;
  background-color: #ffffff29;
  border: 1px solid #d0d5dd;
  padding: 8px 16px 8px 12px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  margin-top: 16px;
  width: 100%;
  justify-content: center;
  gap: 6px;
}
.listOfWinnersButtonMobile {
  background-color: #d6ff00;
  border: 1px solid #b8db00;
  padding: 8px 16px;
  border-radius: 6px;
  color: #252f3b;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  margin-top: 16px;
}
.listOfWinnersButtonDesktop {
  display: none;
}
.backButtonMobile img {
  width: 20px;
  height: 20px;
}
.backButtonDesktop {
  display: none;
}
.awards {
  width: 100%;
}

.awardsItemHeader {
  margin: 0 auto;
  margin-top: 16px;
  padding: 0px 16px;
  margin-top: 24px;
}

.awardsItemHeader h4 {
  color: #d6ff00;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
.awardsItemHeader p {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-top: 4px;
}
.awardsItem {
  border: 1px solid #262c39;
  box-shadow: 0px 1px 3px 0px #1018281a;
  background-color: #41434b;
  border-radius: 8px;
  margin-top: 8px;
}
.awardsItem > ul > li {
  display: flex;
  align-items: center;
  gap: 24px;
  color: #fff;
  padding: 12px;
  border-top: 1px solid #212631;
}
.awardsItem > ul > li:first-child {
  border-top: none;
  background-color: #353945;
}
.awardsItem > ul > li:last-child {
  border-bottom: none;
  background-color: #353945;
}
.userInfo {
  display: flex;
  align-items: center;
  gap: 8px;
}
.userInfo > img {
  width: 44px;
  height: 44px;
  border-radius: 6px;
  object-fit: cover;
}
.awardsItem > ul > li > img {
  width: 44px;
  height: 44px;
}
.noAwards {
  padding: 5rem;
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: 600;
}
.pagination {
  margin-top: 1rem;
  padding: 0 1rem;
}
@media (min-width: 768px) {
  .mainContainer {
    flex-direction: row;
  }

  .pagination {
    padding: 0 2px;
  }
}
@media (min-width: 1024px) {
  .mainContainer {
    flex-direction: row;
  }

  .pageHeader h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .backButtonMobile {
    display: none;
  }
  .backButtonDesktop {
    display: flex;
    align-items: center;
    background-color: #ffffff29;
    border: 1px solid #d0d5dd;
    padding: 8px 16px 8px 12px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px;
    justify-content: center;
    gap: 6px;
    position: absolute;
    left: 0;
  }
  .backButtonDesktop img {
    width: 20px;
    height: 20px;
  }
  .listOfWinnersButtonMobile {
    display: none;
  }
  .listOfWinnersButtonDesktop {
    display: block;
    padding: 8px 16px;
    background-color: #d6ff00;
    border: 1px solid #b8db00;
    color: #252f3b;
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    position: absolute;
    right: 0;
  }
}
