.headerContainer > h1{
    text-align: center;
    color: #D6FF00;
    font-size: 28px;
    font-weight: 700;
    margin-top: 16px;
    border-top: 2px solid #D6FF00;
    border-bottom: 2px solid #D6FF00;
    padding: 10px;
}

.contentContainer {
  text-align: center;
  margin-top: 16px;
  padding: 0px 16px;
  color: #fff;
  background-color: #353945;
  border: 1px solid #212631;
  margin: 16px 12px;
  border-radius: 8px;
}

.content > h3 {
  margin-top: 2rem;
  color: #d6ff00;
  font-weight: 500;
  font-size: 1.2em;
  margin-bottom: -1rem;
}
.dropdownButton{
  background-color: #2B3240;
  border: 1px solid #212631;
  padding: 12px 16px;
  color: #fff;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  text-align: start;
  margin-top: 16px;
}

.titleWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.active {
  border: 1px solid #D6FF00 !important; 
  border-radius: 8px;
  color: #D6FF00 !important; 
}
.toggleIcon{
  width: 18px;
  height: 18px;
}
.divider {
   border: 1px solid #D6FF00;
    width: 100%;
    margin: 16px 0px;
}
.textWrapper{
  color: #fff;
}

