.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 68px;
}

.promoContainer {
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: center;
  display: flex;
  gap: 5px;
  position: absolute;
  height: 68px;
  background-color: var(--p-color);
}

.promoContainer button {
  background-color: #b8db00;
  border: 1px solid #0000009e;
  color: black;
  box-shadow: 0px 0.5px 0.4px 0px #9b1208;
  padding: 4px;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: space-around;
}

.promoContainer button span {
  display: inline-block;
  font-weight: 520;
  font-size: 2.5em;
  transform: scale(1.2, 1);
  font-family: 'Oxanium';
  font-weight: 550;
  overflow: hidden;
}

.container div {
  width: 100% !important;
  height: 100% !important;
}
