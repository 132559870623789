.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.titleContainer {
  position: relative;
}

.backButton,
.backButtonMobile {
  width: 104px;
  padding: 8px 16px 8px 12px;
  background-color: #ffffff29;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-weight: 500;
  position: absolute;
}

.backButtonMobile {
  display: none;
}

.backButton > img,
.backButtonMobile > img {
  width: 20px;
  height: 20px;
}

.tableButtonsContainer {
  display: flex;
  width: 100%;
  background-color: #212631;
  border-radius: 8px;
  gap: 4px;
  padding: 4px;
  border: 1px solid #212631;
}

.tableButtonsContainer img {
  width: 14px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7476%)
    hue-rotate(246deg) brightness(97%) contrast(104%);
}

.calendarImage {
  position: relative;
}

.tableButtonsContainer .calendarImageInside {
  position: absolute;
  width: 8px;
  left: 3px;
  top: 3px;
}

.tableButtonsContainer button {
  flex: 1;
  border: none;
  background-color: #212631;
  color: white;
  display: flex;
  gap: 4px;
  border-radius: 6px;
  padding: 6px;
  justify-content: center;
}

.tableButtonActive {
  color: #1d2939 !important;
  background-color: #d6ff00 !important;
}

.tableButtonActive img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(85%) saturate(7500%)
    hue-rotate(192deg) brightness(85%) contrast(112%);
}

.menuButton {
  display: none;
  background-color: transparent;
  border: 1px solid #212631;
  border-radius: 8px;
  padding: 8px 12px;
  justify-content: space-between;
  color: white;
  margin: 0 10px;
}

.menuButton img {
  width: 14px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%)
    hue-rotate(213deg) brightness(110%) contrast(107%);
  transform: rotate(180deg);
}

.activeButton {
  background-color: var(--a-color);
  color: black;
}

.activeButton img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(7489%)
    hue-rotate(69deg) brightness(108%) contrast(101%);
  transform: rotate(0deg);
}

@media (max-width: 768px) {
  .backButton {
    display: none;
  }

  .backButtonMobile {
    display: block;
    position: static;
    width: 95%;
    margin: 0 auto;
    justify-content: center;
    display: flex;
  }

  .titleContainer {
    margin-top: 10px !important;
  }

  .tableButtonsContainer {
    flex-direction: column;
    gap: 1rem;
    width: calc(100% - 20px);
    margin: 0 auto;
  }

  .tableButtonsContainer button {
    border: 1px solid #454f63;
  }

  .menuButton {
    display: flex;
  }
}
