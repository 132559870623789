.shopAdminProducts * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.shopAdminProducts {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.top > div {
  display: flex;
  align-items: center;
  gap: 20px;
}

.searchTermInput {
  width: 300px;
  padding: 0.3rem 0.5rem;
}

.searchTermInput:focus,
.searchTermInput:focus-within {
  background-color: white !important;
}

.addButton {
  margin-bottom: 20px;
  padding: 0.5rem;
}
.productTableContainer {
  display: flex;
}
.productTable {
  width: 100%;
  flex: 1;
  height: calc(100vh - 130px);
  overflow: auto;
}

.tableImageWrapper {
  height: 35px;
  width: 35px;
}

.categorySelectWrapper {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  color: white;
}

.required {
  color: #ff4d4f;
}

.categorySelectWrapper select {
  background-color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  outline: none;
  border: 1px solid rgb(214, 212, 212);
  width: 100%;
}

.attributesNote {
  margin-bottom: 1rem;
  display: block;
  color: white;
}

.deleteIcon {
  height: 70px;
  width: 70px;
  color: red;
  display: block;
  margin: 0 auto;
}

.uploadWrapper {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
}
.ant-picker-dropdown {
  z-index: 1056 !important;
}

.exipreSpan {
  display: block;
  margin-bottom: 5px;
  color: white;
}

.react-datepicker-popper {
  z-index: 55 !important;
}

.tourDatePickers {
  display: flex;
  color: white;
}
