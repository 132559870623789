.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: 24px;
  width: 100%;
  max-width: 343px;
  overflow: auto;
  border: 1px solid #212631;
  background-image: linear-gradient(to top left, #111219, #40445E);
  border-radius: 12px;
}
.container > button {
  border: 1px solid #212631;
  background-color: #D6FF00;
  width: 100%;
  max-width: 302px;
  padding: 6px;
  color: #1D2939;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
  margin-top: 16px;
}
.container > input {
  background-color: #FFFFFF5C;
  padding: 8px;
  width: 100%;
  max-width: 302px;
  border: none;
height: 36px;
margin-top: 16px;
}
.container > input::placeholder{
  color: #2B2E40;
  font-size: 14px;
  font-weight: 500;
}
.info {
  color: #FFFFFFDB;
  max-width: 310px;
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 16px;
  margin-top: 16px;
}

.btnContainers {
  display: flex;
  width: 300px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.btn:first-child {
  padding: 12px 24px;
  background-color: #D6FF00;
  border:1px solid #B8DB00;
  border-radius: 6px;
  width: 100%;
  max-width: 302px;
  color: #252F3B;
  font-size: 16px;
  font-weight: 500;
  height: 44px;
}
.btn:last-child{
  background-color: transparent; 
  border: none;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  max-width: 302px;
  color: #FFFFFF;
  margin-top: 16px;
}
.error > p {
  color: red;
  font-size: 22px;
  font-weight: bolder;
}
