.sideImages {
  display: none;
  flex-direction: column;
  gap: 24px;
  margin-right: 10px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  padding-top: 24px;
}

.mobileSideImages {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: fit-content;
  justify-content: center;
  gap: 1rem;
  padding: 16px 5px;
  margin: 0 auto;
}

.mobileSideImages .semaforiButton {
  display: none;
}

.mobileSideImages .adItem {
  max-width: 300px;
}

.mobileSideImages .adItem img,
.mobileSideImages .adItem video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.adItem {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  max-width: 200px;
  overflow: hidden;
  background-color: white;
  border: 2px solid #d6ff00;
  border-radius: 0.5rem;
}

.semaforiButton {
  border: none;
  border-radius: 6px;
  font-weight: 500;
  max-width: 200px;
  font-size: 14px;
  background-color: var(--a-color);
  border: 2px solid #d6ff00;
  padding: 0.5rem 1.5rem;
  line-height: 20px;
}

@media (min-width: 768px) {
  .sideImages {
    display: flex;
    max-width: 210px;
  }
}

@media (max-width: 1280px) {
  .sideImages {
    padding-left: 1rem;
  }
}
