.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.paginationContainer button {
  min-width: 40px;
  width: auto;
  height: 40px;
  color: white;
  background-color: #454a59;
  border: 1px solid #212631;
  border-right: none;
  padding: 10px;

  font: Inter;
  font-size: 14px;
}

.paginationContainer button:disabled {
  color: #ffffff65;
  cursor: default;
}

.active {
  color: #d6ff00 !important;
}

.cornerButton {
  background-color: #3a3e4b !important;
}

.cornerButton:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.cornerButton:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
