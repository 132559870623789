@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@400;600;700&display=swap');

.appContainer {
  height: 100vh;
  overflow: auto;
  position: relative;
}

.imgCover {
  background-color: black;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  pointer-events: none;
}

.btnsWrapper {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.leftButton,
.rightButton {
  width: 100%;
  background-color: transparent;
}

.leftButton {
  height: 77%;
  border: none;
  outline: none;
}

.rightButton {
  height: 23%;
}

h1 {
  font-family: 'Oxanium', sans-serif;
}

.refreshContainer {
  width: 100%;
  margin: auto;
  margin-top: -30px;
  position: relative;
  transition: margin-top 0.2s ease-out;
}
.refreshContainer .refreshIcon {
  width: 30px;
  height: 30px;
  background-color: #0000001a;
  border: 3px solid #0000001a;
  border-radius: 50%;
  margin: 0 auto;
}
.refreshContainer .refreshIcon svg {
  filter: brightness(0) saturate(100%) invert(100%) sepia(32%) saturate(6848%)
    hue-rotate(12deg) brightness(102%) contrast(103%);
}
