.cartHolder{
  margin: 0px 16px;
}
.cartContainer {
  background-color: #353945;
  border: 1px solid #212631;
  width: 100% !important;
  height: 100%;
  overflow: auto;
  scrollbar-width: none;
  height: 420px;
  margin-top: 32px;
  padding: 16px;
  border-radius: 8px;
  margin-left: 0px;
}
.cartHeader > h3 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}
.formItem > label {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
}
.userInfoForm {
  margin-top: 16px;
}
.input {
  background-color: #262c39;
  border: none;
  margin-top: 8px;
  width: 95%;
  color: #fff !important;
}
.input::placeholder {
  color: #fff !important;
  background-color: #262c39;
}
.input:hover, .input:focus, .input:not(:focus) {
  background-color: #262c39 !important;
  color: #fff !important;
}

.orderInfo {
  background-color: #262c39;
  border: 1px solid #d6ff00;
  padding: 12px;
  border-radius: 8px;
}
.orderInfo > p:first-child {
  color: #d6ff00;
}
.orderInfo > p {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}
.cartSection {
  background-color: #353945;
  border: 1px solid #212631;
  width: 100% !important;
  height: 100%;
  overflow: auto;
  scrollbar-width: none;
  height: 290px;
  margin-top: 24px;
  padding: 16px;
  border-radius: 8px;

}
.cartWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.auctionForm{
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  
}
.formItem >span{
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
}
.formItem > span:last-child{
  background-image: linear-gradient(#F1F0F3, #737175);
  color: transparent;
  background-clip: text;
  font-size: 18px;
  font-weight: 600;
}
.auctionForm > button{
  background-color: #D6FF00;
  border: 1px solid #B8DB00;
  padding: 12px 24px;
  border-radius: 6px;
  color: #252F3B;
  font-size: 16px;
  font-weight: 500;
  margin-top: 100px;
  width: 100%; 
}
.auctionForm > span{
  color: #FFFFFF;
}
.auctionForm >input{
  background-color: #262c39 !important;
  border: none;
  color: #fff;
}
.sportTourBtn{
  margin-top: 125px !important;
}
.cartTitle {
  color: #d6ff00;
  font-size: 16px;
  font-weight: 500;
}
.productImage > img {
  width: 92px;
  height: auto;
  border-radius: 8px;
}
.productTitle {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
}
.quantitySection {
  display: flex;
  align-items: center;
  gap: 12px;
}
.quantityControls {
  background-color: #2b3240;
  border: 1px solid #212631;
  padding: 4px;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 16px;
}
.quantity {
  background-color: #FFFFFF;
  border-radius: 8px;
  color: #252F3B;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 10px;
}
.quantitySection > p {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  margin-top: 16px;
}
.quantityBtn {
  background-color: transparent;
  border: none;
}
.quantityIcon {
  color: #fff;
  background-color: transparent !important;
}
.divider {
  border: 1px solid #212631;
  width: 90%;
}
.totalSum {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.totalSum > p {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
}
.totalSum > span {
  background-image: linear-gradient(#f1f0f3, #737175);
  color: transparent;
  background-clip: text;
  font-size: 28px;
  font-weight: 700;
}
.submitBtn {
  background-color: #d6ff00;
  border: 1px solid #b8db00;
  padding: 12px 24px;
  border-radius: 6px;
  color: #252f3b;
  box-shadow: 0px 0px 8px 0px #d6ff003d;
  margin-top: 16px;
  width: 95%;
  font-size: 16px;
  font-weight: 500;
}
@media (min-width: 1024px) {
  .cartHolder {
    display: flex;
    align-items: start;
    gap: 24px;
    margin: 0px 16px 0px 0px!important;
  }
  .cartContainer {
    max-width: 783px;
    margin-top: 24px;
    margin-left: 16px ;
  }
  .cartSection {
    max-width: 340px;
  }
  .formItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
  }
  .input {
    width: 520px;
    margin-top: 16px;
  }
  .orderInfo {
    margin-top: 16px;
  }
}
