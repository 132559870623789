.container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  color: #ffff;
  font-size: 1.5em;
}

.container > h1 {
  margin-top: 24px;
  text-align: center;
  border-top: 2px solid #d6ff00;
  border-bottom: 2px solid #d6ff00;
  color: #d6ff00;
  font-weight: 600;
  font-size: 28px;
  padding: 10px;
}
.container > p {
  margin-top: 24px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
}

.container > h3 {
  color: #d6ff00;
  padding: 20px;
  border-top: 2px solid #d6ff00;
  border-bottom: 2px solid #d6ff00;
  text-align: center;
  margin-top: 12px;
}

.container > ul > li {
  margin-top: 24px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
}
