.contentContainer {
  flex: 2;
}

.titleContainer {
  position: relative;
}

.backButton,
.backButtonMobile {
  width: 104px;
  padding: 8px 16px 8px 12px;
  background-color: #ffffff29;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-weight: 500;
  position: absolute;
}

.backButtonMobile {
  display: none;
  position: static;
  width: 95%;
  margin: 0 auto;
  justify-content: center;
}

.backButton > img,
.backButtonMobile > img {
  width: 20px;
  height: 20px;
}

.profileContainer {
  width: 100%;
  background-color: #353945;
  border: 1px solid #212631;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  border-radius: 8px;
}

.profileContainer img {
  width: 96px;
  aspect-ratio: 1;
}

.profileContainer .userInfo {
  display: flex;
  justify-content: flex-start;
  color: #ffffffad;
  font-size: 12px;
  margin-inline: 0.5rem;
  font-weight: 400;
  padding: 0.5rem;
  flex-direction: column;
  gap: 16px;
}

.profileContainer .userDetailsContainer {
  display: flex;
}

.profileContainer .userDetails {
  display: flex;
  flex-direction: column;
}

.profileContainer .userRole {
  display: flex;
  align-items: center;
  gap: 2px;
}

.profileContainer .userName {
  color: var(--a-color);
  font-size: 1rem;
  line-height: 22px;
  font-weight: 500;
  word-break: break-word;
}

.profileContainer .userIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  aspect-ratio: 1;
}

.profileContainer .scoreContainer {
  display: flex;
  width: 100%;
  gap: 10px;
}

.scoreContainer .scoreInner {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  width: 100%;
}

.scoreContainer button,
.sideContainer button,
.scoreContainer .rank,
.scoreContainer .score,
.mobileTableContainer button {
  height: 48px;
  padding: 6px;
  background-color: #2b3240;
  border: 1px solid #212631;
  border-radius: 8px;
  box-sizing: border-box;
  color: white;
}

.scoreContainer .rank,
.scoreContainer .score {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
}

.scoreContainer .scoreInner button {
  grid-column: span 2;
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.scoreContainer .scoreInner button img {
  width: 50px;
}

.scoreContainer .scoreInner .rank {
  grid-column: span 1;
  color: #d6ff00;
}

.scoreContainer .scoreInner .score {
  grid-column: span 1;
  color: #cabb90;
}

.sideContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
}

.sideContainer .tableButtons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 320px;
}

.sideContainer .tableButtons > span > img {
  width: 18px;
}

.sideContainer .tableButtons > span {
  position: relative;
  display: flex;
  gap: 10px;
  color: var(--a-color);
}

.sideContainer .tableButtons > span > .calendarImageInside {
  width: 10px;
}

.calendarImageInside {
  position: absolute;
  width: 10px;
  top: 3px;
  left: 4px;
}

.sideContainer .tableButtons button {
  height: 30px;
  background-color: #262c39;
}

.sideContainer .sideAdContainer {
  overflow: hidden;
  border-radius: 12px;
  max-width: 320px;
  position: relative;
}

.sideContainer .sideAdContainer:hover {
  cursor: pointer;
}

.mobileTableContainer {
  display: none;
  position: relative;
  flex-direction: column;
  gap: 10px;
  padding: 13px;
}

.mobileTableContainer button {
  display: flex;
  justify-content: center;
  padding: 0 10px;
  align-items: center;
  width: 100%;
  background-color: #262c39;
}

.mobileTableContainer .menuButton {
  justify-content: space-between;
  position: relative;
}

.mobileTableContainer button > div {
  display: flex;
  gap: 10px;
  align-items: center;
}

.mobileTableContainer img {
  width: 18px;
  filter: brightness(0) saturate(100%) invert(93%) sepia(93%) saturate(0%)
    hue-rotate(246deg) brightness(106%) contrast(104%);
}

.mobileTableContainer button > img {
  width: 14px;
  transform: rotate(180deg);
}

.mobileTableContainer .activeButton {
  background-color: var(--a-color);
  color: black;
}

.mobileTableContainer .activeButton img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(7461%)
    hue-rotate(347deg) brightness(105%) contrast(111%);
}

.mobileTableContainer .activeButton > img {
  transform: rotate(0deg);
}

.mobileTableContainer .calendarImageInside {
  left: 14px;
  width: 10px;
}

@media (max-width: 1390px) {
  .backButton {
    display: none;
  }

  .backButtonMobile {
    display: flex;
  }

  .titleContainer {
    margin-top: 10px !important;
    margin-bottom: 1rem;
  }
}

@media (max-width: 768px) {
  .sideContainer {
    display: none;
  }

  .scoreContainer {
    flex-direction: column;
  }

  .mobileTableContainer {
    display: flex;
  }
}
