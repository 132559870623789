.pagination {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 14px;
}

.pagination button {
  min-width: 98px;
  padding: 8px 14px;
  background-color: #ffffff29;
  color: white;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .pagination {
    font-size: 13px;
  }
}
