.toggleButtonContainer {
  width: 100%;
  margin: 0 auto;
  margin-top: 16px;
}

.toggleButton {
  background-color: #ffffff29;
  color: white;
  padding: 6px 14px 6px 10px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  transition: 0.3s ease;
  width: 100%;
  height: 40px;
}

.container {
  display: none;
}

.container.visible {
  display: block;
  background-color: #353945;
  width: 100%;
  padding: 0;
  overflow: scroll;
  border-radius: 12px;
  border: 1px solid #212631;
}

.container ul {
  margin: 0 auto;
  padding: 8px;
  width: 100%;
  list-style: none;
}

.liTitle {
  background: transparent;
  color: #d6ff00;
  text-align: start;
  padding: 12px 15px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  margin-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 1.2;
}

.liItem {
  background: #262c39;
  color: #e5e7eb;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 16px;
  border-radius: 6px;
  margin-bottom: 8px;
  text-align: start;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid transparent;
  gap: 16px;
  letter-spacing: 0.3px;
  line-height: 1.4;
}

.itemTitle {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selected {
  border: 1px solid #d6ff00;
  color: #d6ff00;
}

.selected .itemTitle {
  color: #d6ff00;
}

.soonButton {
  background: #353945;
  color: #d0d5dd;
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 12px;
  min-width: 70px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  flex-shrink: 0;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1;
  gap: 6px;
  cursor: pointer;
  
}

.playIcon {
  width: 14px;
  height: 14px;
  color: #d0d5dd;
  
}

@media (min-width: 768px) {
  .toggleButton {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .toggleButton {
    display: none;
  }

  .container {
    display: inline-block;
    height: calc(100dvh - 200px) !important;
    background-color: #353945;
    border-radius: 12px;
    padding: 8px;
    overflow: scroll;
  }

  .container::-webkit-scrollbar {
    width: 8px;
  }

  .container::-webkit-scrollbar-track {
    background: #1e2530;
    border-radius: 4px;
  }

  .container::-webkit-scrollbar-thumb {
    background: #454c5e;
    border-radius: 4px;
  }

  .container::-webkit-scrollbar-thumb:hover {
    background: #555f77;
  }

  .toggleButtonContainer {
    width: 100%;
    max-width: 362px;
    margin: 0 auto;
  }

  .container ul {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    max-width: 327px;
    list-style: none;
  }
}
