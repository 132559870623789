.authContainer {
  width: 100%;
  max-width: 342px;
  margin: 20px auto;
  padding: 12px;
  background-color: #353945;
  border: 1px solid #212631;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  overflow: hidden;
  transition: height 0.3s ease;
}
.green {
  display: block;
  color: #d6ff00;
  font-weight: 500;
  font-size: 12px;
  max-width: 520px;
  width: 100%;
}
.loginHeight {
  height: 240px;
}

.registrationHeight {
  height: 460px;
}
.authTabs {
  display: flex;
  justify-content: space-between;
  gap: 4px;
  margin-bottom: 12px;
  border: 1px solid #212631;
  border-radius: 8px;
}

.tabButton {
  flex: 1;
  padding: 6px;
  border: none;
  background-color: #2c2c3e;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 6px;
}

.activeTab {
  background-color: #d6ff00;
  color: #000;
}
.registrationForm {
  height: 100%;
  max-height: 392px;
}
form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.input {
  width: 100%;
  padding: 8px;
  border: none;
  border-radius: 8px;
  background-color: #262c39 !important;
  color: #fff !important;
}
.input .antInput{
  background-color: #262c39 !important;
  color: #fff !important;
}
.input:focus,
.input:hover,
.input:active {
  background-color: #262c39 !important;
  color: #fff !important;
}
.input:focus-within{
  background-color: #262c39 !important;
  color: #fff !important;
}
.input::placeholder {
  color: #ffffff !important;
}
::-webkit-input-placeholder {
  color: #fff !important;
}
.checkboxContainer {
  display: flex;
  align-items: center;
  text-align: start;
  gap: 4px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  line-height: 18px;
}

.checkboxContainer label a {
  color: #fff;
  text-decoration: underline;
}
.checkboxContainer:first-of-type {
  padding-top: 12px;
}
.submitButton {
  width: 100%;
  padding: 12px 24px;
  background-color: #d6ff00;
  color: #000;
  border: 1px solid #b8db00;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 12px;
}

.submitButton:disabled {
  background-color: #b0b0b0;
  cursor: not-allowed;
}

.errorMessage {
  color: #ff4d4f;
  font-size: 14px;
  margin-bottom: 10px;
}

.forgotPassword p {
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
}
.loginForm {
  margin-bottom: 12px;
}
