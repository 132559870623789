.sidebar {
  position: absolute;
  bottom: 100vh;
  top: 60px;
  right: 0px;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  /* max-width: 768px; */
  height: calc(100dvh - 60px);
  z-index: 100;
  justify-content: flex-start;
  background-color: var(--p-color);
  overflow: auto;
}

.authBtnWrapper {
  background-color: #262c39;
  padding: 1rem;
  border: 1px solid #212631;
  width: 100%;
  height: fit-content;
  
}

.authBtnWrapper button:first-child {
  background-color: #d6ff00;
  border: 1px solid #b8db00;
  color: #252f3b;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  width: 100%;
}
.authBtnWrapper button {
  background-color: #0000009e;
  border: 1px solid #0000009e;
  color: #d6ff00;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  width: 100%;
  margin-top: 16px;
  font-family: Oxanium !important;
}
.addShortcut {
  background-color: white !important;
  color: #2b3240 !important;
  border: 1px solid white !important;
}
.linksContainer {
  padding: 1rem;
}

.menuItem {
  margin-bottom: 1rem;
  
}

.menuButton {
  background: none;
  color: white;
  border: none;
  width: 100%;
  text-align: left;
  padding: 0.5rem 1rem;
  cursor: pointer;
  background-color: #353945;
  color: white;
  border: 1px solid #212631;
  padding: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 12px;
  gap: 4px;
  font-family: Oxanium !important;
}

.submenuIconWrapper {
  margin-left: auto;
}

.menuItem .submenuIconWrapper img {
  transform: rotate(180deg);
  filter: brightness(0) invert(1);
}

.menuItem .itemIconWrapper img {
  filter: brightness(0) invert(1);
}

.activeMenuItem {
  color: var(--a-color);
  background-color: #d6ff00;
  color: #252f3b;
}

.activeMenuItem .itemIconWrapper img {
  filter: none;
}

.activeMenuItem .submenuIconWrapper img {
  color: var(--a-color);
  filter: none;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.submenu {
  display: flex;
  flex-direction: column;
}

.submenuButton {
  background: none;
  color: white;
  border: none;
  border-bottom: 1px dashed #ffffffad;
  cursor: pointer;
  outline: none;
  color: #d6ff00;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  font-family: Oxanium !important;
  /* TODO */
  padding: 18px 8px;
}

.submenuButton:hover {
  background-color: #1c2330;
}

/* FOOTER */

.sidebarFooter {
  width: 100%;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-top: 1px solid #212631;
}

.sidebarFooter button {
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebarFooterLinks {
  display: flex;
  gap: 1rem;
  justify-content: center;
  padding-top: 1rem;
}

.sidebarFooterLinks button {
  color: white;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
}

.sidebarSocialMediaBtns {
  display: flex;
  gap: 22px;
  justify-content: center;
  margin-block: 24px 16px;
}

@media (min-width: 1217px) {
  .sidebar {
    display: none;
  }
}
