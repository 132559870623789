.mainContainer {
  width: 100%;
  min-height: 100vh;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  overflow: auto;
}
.headerContainer {
  border-bottom: 2px solid #d6ff00;
  border-top: 2px solid #d6ff00;
  width: 100%;
  padding: 16px;
  text-align: center;
}

.headerContainer > h1 {
  font-size: 28px;
  font-weight: 600;
  font-family: Oxanium;
  color: #d6ff00;
  margin: 0;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  gap: 24px;
}

.textContainer {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 16px;
  border-radius: 12px;
  height: 670px;
  overflow-y: auto;
}

.textContainer::-webkit-scrollbar {
  width: 8px;
}

.textContainer::-webkit-scrollbar-track {
  background: #1e2530;
  border-radius: 4px;
}

.textContainer::-webkit-scrollbar-thumb {
  background: #454c5e;
  border-radius: 4px;
}

.textContainer::-webkit-scrollbar-thumb:hover {
  background: #555f77;
}

.textContainer img {
  width: 100%;
  height: auto;
  margin: 24px auto;
  border-radius: 8px;
}

.textContainer p {
  margin-bottom: 16px;
  line-height: 1.6;
}

@media (min-width: 1024px) {
  .mainContainer {
    padding: 24px;
  }

  .contentWrapper {
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
  }

  .textContainer {
    flex: 1;
    overflow: auto;
  }

  .mainContainer img {
    width: 500px;
    margin-bottom: 100px;
  }
}