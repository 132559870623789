.mainCenter {
  overflow-y: auto;
  width: 100%;
  padding-left: 16px;
}
.headerContainer {
  width: 100%;

  margin: 0 auto;
  margin-top: 24px;
  text-align: center;
}
.headerContainer > h1 {
  color: var(--a-color);
  border-bottom: 2px solid var(--a-color);
  width: 100%;
  padding-bottom: 10px;
  text-align: center;

  display: block;
}
.container {
  width: 100%;
  background-color: #353945;
  border: 1px solid #212631;
  margin-top: 24px;
  border-radius: 8px;
  overflow: auto;
  min-height: 470px;
}
.profileImageContainer {
  padding: 16px;
  display: flex;

  align-items: start;
  gap: 16px;
}
.profileImageContainer > span {
  color: #d6ff00;
}
.profileImageContainer > img {
  width: 96px;
  height: 100%;
  border-radius: 8px;
}
.profileImageContainerEdit {
  padding: 16px;
  display: flex;

  align-items: start;
  gap: 16px;
}
.profileImageContainerEdit > span {
  color: #d6ff00;
}
.profileImageContainerEdit > img {
  width: 96px;
  height: 100%;
  border-radius: 8px;
}
.btnContainer {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 0 auto;
  width: 100%;
  max-width: 311px;
  height: 40px;
}
.btn {
  border-radius: 8px;
  border: 1px solid #212631;
  background-color: #262c39;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
  height: 100%;
  width: 100%;
}
.btn:last-child {
  border: 1px solid #d0d5dd;
  background-color: #ffffff29;
}
.changeProfileBtnContainer {
  margin-bottom: 16px;
}
.input {
  background-color: #262c39;
  color: #fff;
  border: none;
  padding: 8px;
  border-radius: 8px;
  margin-top: 4px;

  width: 100%;
}
.showPassword {
  width: 18px;
  height: 18px;
  position: relative;
  right: 30px;
}
.changeProfileBtnContainer > .btn:last-child {
  background-color: #d6ff00;
  border: 1px solid #b8db00;
  color: #252f3b;
}
div > .single {
  margin-bottom: 16px;
}
.right {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 16px;
}
.single {
  display: flex;
  flex-direction: column;
  color: #fff;
  gap: 12px;
}
.single > span {
  color: #ffffffad;
  padding-left: 8px;
}
.single:last-child {
  padding-bottom: 16px;
}
.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.inputWrapper input {
  width: 100%;
  padding: 10px 40px 10px 10px;

  border-radius: 5px;
  font-size: 16px;
}

.inputWrapper .showPassword {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px; /* Veličina ikonice */
  height: 20px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .mainCenter {
    padding-left: 0;
    justify-content: center;
  }

  .adImage {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .inputWrapper input {
    padding: 10px 30px 10px 10px; /* Možeš smanjiti padding na manjim ekranima */
  }

  .inputWrapper .showPassword {
    width: 18px;
    height: 18px;
  }
  .singleWithText {
    display: flex;
    flex-direction: column;
  }
}

.adminButtons > h3 {
  color: #d6ff00;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 24px;
  width: 100%;
  max-width: 320px;
}
.adminButtons > h3 > img {
  height: 24px;
  width: 24px;
}

.admin {
  color: #ffffff;
  text-decoration: none;
  font-size: 13px;
  font-weight: 500;
  background-color: #262c39;
  border: 1px solid #212631;
  margin-top: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 8px;
  border-radius: 6px;
}
.admin:first-child {
  margin-top: 12px;
}
.admin:last-child {
  margin-top: 8px;
  margin-bottom: 24px;
}
.admin > a {
  text-decoration: none;
  color: #fff;
  margin: 0 auto;
  padding: 6px 24px;
}
.green {
  display: none;
}
@media (max-width: 768px) {
  .mainCenter {
    padding-left: 0px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 856px;
    height: 100%;
    max-height: 482px;
  }
  .left {
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }
  .profileImageContainer {
    padding: 0px;
  }
  .containerWrapper {
    display: flex;
    gap: 24px;
  }
  .btnContainer {
    margin: 0;
  }
  .right {
    width: 100%;
    max-width: 520px;
  }

  .single {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
    flex-direction: row;
  }

  .single span {
    color: #f5f5f5;
  }

  .changeTeamButton {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 14px;
  }

  .changeTeamButton:hover {
    text-decoration: underline;
  }
  .editContainer {
    background-color: #353945;
    border: 1px solid #212631;
    border-radius: 8px;
    padding: 16px 16px 0px 16px;
    margin-top: 24px;
    width: 100%;
    max-width: 856px;
    height: 100%;
    max-height: 644px;
  }
  .formHeaderWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .profileImageContainerEdit {
    padding: 0px;
  }
  .inputWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 520px;
    width: 100%;
  }
  .input {
    max-width: 520px;
    width: 100%;
    margin-bottom: 0px;
  }
  .passwordWrapper > .single {
    display: flex;
    flex-direction: column;
  }
  .green {
    display: block;
    color: #d6ff00;
    font-weight: 500;
    font-size: 12px;
    padding-top: 16px;
    max-width: 520px;
    width: 100%;
  }
}
