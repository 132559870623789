.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 0.5rem;
  height: 100%;
  flex-wrap: wrap;
  padding-bottom: 40px;
  box-sizing: border-box;
  flex-grow: 1;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.msg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 32px;
  font-weight: bold;
}

@media screen and (max-width: 1220px) {
  .container {
    padding: 0 3px;
  }
}
