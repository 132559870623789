.container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  width: 165px;
  height: auto;
  cursor: pointer;
  flex-shrink: 0;
  padding: 4px;
  background-color: #353945;
  border-radius: 8px;
  border: 2px solid #212631;
  justify-content: space-between;
}
.korisnickiPaketi {
  width: 155px;
}
/* .container:has(.commentCount) {
  height: calc(inherit + 2rem);
} */

.container > img {
  width: 100%;
  height: 106px;
  object-fit: cover;
  border-radius: 4px;
  border-bottom: 2px solid #333;
}

.container > video {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 4px;
  width: 100%;
}

.title {
  overflow-y: visible;
  /* min-height: 5rem; */
  height: 100%;
  position: relative;
}

.title h2 {
  font-size: 14px !important;
  line-height: 1.2;
  color: #ffffff;
  padding: 5px;
  font-weight: 600;
  /* height: 3rem; */

  text-align: left;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.infoSection {
  background: linear-gradient(to bottom right, #3b3e56, #111219);
  color: #d6ff00;
  padding: 3px 10px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  border: 1px solid #1a1e28;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.commentCount {
  font-size: 14px;
}

.dateAndTime {
  font-size: 12px;
}
@media (min-width:1024px) {
  .korisnickiPaketi{
    width: 160 px;
  }
}