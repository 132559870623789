.mainCenter {
  width: 100%;
  text-align: start;
  overflow: auto;
  box-sizing: border-box;
  
}
.mainCenter > h1 {
  margin-top: 24px;
  text-align: center;
  border-top: 2px solid #d6ff00;
  border-bottom: 2px solid #d6ff00;
  color: #d6ff00;
  font-weight: 600;
  font-size: 28px;
  padding: 10px;
}
.mainCenter > p {
  margin-top: 24px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  padding:16px;
}

.mainCenter > img {
  width: 100%;
  height: 200px;
  margin-top: 24px;
  display: block;
  text-align: start;
  padding:16px;
}
@media (min-width: 1024px) {
  .mainCenter {
    padding-left: 0px;
  }
}
