.comment {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  background-color: #353945;
  border: 1px solid #212631;
  border-radius: 0.5rem;
  flex-grow: 1;
  flex-grow: 1;
}

.mainCenter {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 40px;
  box-sizing: border-box;
  word-wrap: break-word;
  flex-grow: 1;
}

.logInContainer {
  text-align: center;
  margin: 10px 0px;
}

.logInContainer > span {
  font-size: 20px;
  font-weight: 700;
  /* font-family: 'Comic Sans', sans-serif; */
  line-height: normal;
  text-transform: uppercase;
}

.textArea {
  width: 100%;
  height: 100%;
  display: flex;
  resize: none;
  box-sizing: border-box;
  outline: none;
  padding: 10px;
  border: 2px solid gray;
  border-radius: 6px;
  font-size: 20px;
}

.textArea:focus + .placeholder {
  opacity: 0;
}

.placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 1;
  color: gray;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: all 0.15s ease;
  text-transform: uppercase;
  font-family: 'Comic Sans', sans-serif;
}

.leaveCommentButtonContainer {
  width: 100%;
}

.leaveCommentButtonContainer > button {
  width: 100%;
  background-color: rgb(0, 0, 90);
  color: white;
  font-weight: 700;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  font-family: 'Comic Sans', sans-serif;
  padding: 5px;
  margin-top: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
}

.leaveCommentButtonContainer > button:hover {
  background-color: rgb(0, 0, 60);
}

.top {
  display: flex;
  gap: 10px;
}

.top.reverse {
  flex-direction: row-reverse;
}

.bottom {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.bottom.reverse {
  flex-direction: row-reverse;
}

.commentName {
  width: 100%;
  text-align: center;
}

.commentName > span {
  font-weight: 500;
  font-size: 1rem;
  color: var(--a-color);
  word-break: break-all;
}

.commentUserInfo {
  /* width: 200px; */
  max-width: 200px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* gap: 10px; */
  flex: 1;
}

.commentImage {
  width: 72px;
  height: 72px;
}

.commentImage > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.commentInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  flex: 2;
}

.commentInfoHeader {
  text-align: center;
  margin-bottom: 7px;
}

.commentInfoHeader > :nth-child(1) {
  font-size: 12px;
}

.commentInfoHeader > :nth-child(2) {
  font-size: 10px;
}

.commentInfoHeader > span {
  text-transform: uppercase;
  display: block;
  font-family: 'Comic Sans', sans-serif;
}

.commentDescription {
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  user-select: text;
}

.commentDescription > span {
  text-transform: uppercase;
  word-break: break-word;
  font-family: 'Comic Sans', sans-serif;
  font-size: 12px;
}

.commentDescription > textarea {
  outline: none;
  width: 100%;
  resize: none;
  padding: 10px;
  font-size: 18px;
  margin: 0;
  border-radius: 6px;
  border: 2px solid gray;
  box-sizing: border-box;
}

.commentShowMore {
  text-align: center;
}

.commentShowMore > button {
  border: 2px solid black;
  border-radius: 6px;
  text-transform: uppercase;
  background-color: transparent;
  cursor: pointer;
  font-weight: 700;
  font-family: 'Comic Sans', sans-serif;
  font-size: 14px;
}

.dateAndId {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  min-width: 200px;
  max-width: 200px;
}

.commentActions {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 5px;
}

.commentActions > div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.commentActions button {
  transition: all 0.3s ease;
  font-size: 14px;
  font-family: 'Comic Sans', sans-serif;
}

.commentActions button:hover {
  filter: brightness(0.9);
}

.commentLikes {
  display: flex;
  align-items: center;
  height: 25px;
  width: 100%;
  gap: 3px;
}

.commentLikes > button {
  height: 100%;
  /* width: 30px; */
  border: none;
  border-radius: 5px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0;
  cursor: pointer;
}

.commentLikes > span {
  height: 100%;
  font-size: 14px;
  display: flex;
  flex: 1;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}

.commentLikes > :nth-child(1) {
  background-color: #375623;
}

.commentLikes > :nth-child(1):disabled {
  background-color: #375623;
}

.commentLikes > :nth-child(1):disabled > .likeIcon {
  color: #9bfa20;
}

.commentLikes > :nth-child(4) {
  background-color: #c00000;
}

.commentLikes > :nth-child(4):disabled {
  background-color: #c00000;
}

.commentLikes > :nth-child(4):disabled > .dislikeIcon {
  color: #ff0000;
}

.commentLikes > :nth-child(2) {
  background-color: #9bfa20;
}

.commentLikes > :nth-child(3) {
  background-color: #ff0000;
}

.commentAnswerIt {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.commentAnswerIt > button {
  background-color: #080236;
  min-height: 25px;
  flex-grow: 1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Comic Sans', sans-serif;
  text-transform: uppercase;
}

.commentDelete {
  height: 25px;
}

.commentDelete > button,
.commentEdit > button {
  background-color: rgb(27, 99, 222);
  height: 100%;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-family: 'Comic Sans', sans-serif;
  text-transform: uppercase;
  font-size: 12px;
}

.commentEdit {
  height: 25px;
}

.dateAndTime {
  line-height: 1;
}

.dateAndTime > span {
  font-size: 14px;
  font-family: 'Comic Sans', sans-serif;
  color: rgb(27, 99, 222);
}

.commentId {
  line-height: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.commentId > div > :nth-child(1) {
  font-size: 14px;
}

.commentId > div > :nth-child(2) {
  font-size: 12px;
}

.answerOnCommentClass {
  /* max-height: 0px; */
  /* opacity: 0; */
  margin-bottom: 0px;
  display: none;
  overflow: hidden;
}

.answerOnCommentClass.active {
  /* max-height: 1000px; */
  /* opacity: 1; */
  display: block;
  margin-bottom: 30px;
}

.likeIcon,
.dislikeIcon {
  color: white;
}

.noCommentsContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 30px 0px;
}

.noCommentsContainerIcon {
  font-size: 50px;
  color: gray;
}

.noCommentsContainer > span {
  font-family: 'Comic Sans', sans-serif;
  color: gray;
  font-weight: normal;
}

.adminCommentMessage {
  background-color: #2c3ec4;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  margin: 5px 0;
}

.adminCommentText,
.adminCommentTitle {
  font-family: 'Comic Sans', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
}

.adminCommentText {
  color: white;
}

.adminCommentTitle {
  color: #f4ab3a;
}

.linkToCommentContainer {
  background-color: #0a0d26;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  border: none;
}

.linkToCommentContainer a {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  gap: 5px;
}

.linkToCommentText,
.linkToCommentTitle {
  font-family: 'Comic Sans', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
}

.linkToCommentText {
  color: white;
}

.linkToCommentTitle {
  color: red;
  text-decoration: none;
}

.linkToCommentType {
  color: #ffc000;
  font-family: 'Questrial', sans-serif;
  font-size: 1rem;
}

.linkToCommentPostTitle {
  color: #4bf30a;
}

.reportCommentButtonContainer,
.visitMisterTipsterProfileBtn {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.reportCommentButtonContainer > button,
.visitMisterTipsterProfileBtn > button {
  background-color: #080236;
  width: 100%;
  color: #ed7d31;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 0px 10px;
  text-transform: uppercase;
  font-family: 'Comic Sans', sans-serif;
  font-size: 14px !important;
  transition: all 0.15s ease;
  min-height: 25px;
}

.visitMisterTipsterProfileBtn > button {
  color: white;
  background-color: #000064;
}

.reportCommentButtonContainer > button:hover {
  background-color: rgb(0, 0, 60);
}

.reportCommentButtonContainer > button:disabled {
  background-color: gray;
  color: white;
  cursor: auto;
}

.loginContainerModal,
.registerContainerModal {
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  padding: 0px 20px;
  border-radius: 10px;
  max-width: 300px;
  width: 100%;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  z-index: 2000;
  transition: all 0.3s ease;
}

.loginContainerModal.active,
.registerContainerModal.active {
  visibility: visible;
  pointer-events: all;
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.loginRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 10px;
  text-align: center;
}

.loginRowInfo {
  background-color: rgb(0, 0, 100);
  color: white;
  border-radius: 10px;
  font-family: 'Questrial', sans-serif;
}

.loginRowInfo > p {
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
}

.loginRow > p {
  margin: 0;
}

.loginForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.logovanje > p {
  color: rgb(0, 0, 100);
  font-size: 30px;
}

.loginFormRow > input {
  outline: none;
  background-color: rgb(229, 229, 229);
  border: none;
  border-radius: 4px;
  font-family: 'Questrial', sans-serif;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
}

.forgotPass {
  text-align: center;
}

.forgotPass > button {
  color: rgb(0, 0, 100);
  font-family: 'Questrial', sans-serif;
  background: transparent;
  border: none;
  font-weight: bold;
  font-size: 14px;
  width: max-content;
  cursor: pointer;
}

.loginButton > button {
  background-color: rgb(0, 0, 100);
  color: white;
  border: none;
  border-radius: 6px;
  padding: 7px 10px;
  font-family: 'Questrial', sans-serif;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  cursor: pointer;
}

.registrujSe {
  text-align: center;
}

.registrujSe > span {
  font-size: 14px;
}

.registrujSe > button {
  background-color: transparent;
  border: none;
  color: #573b8a;
  font-weight: 700;
  font-size: 14px;
  font-family: 'Questrial', sans-serif;
  cursor: pointer;
}

.loginOverlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  visibility: hidden;
  pointer-events: none;
  z-index: 1999;
}

.loginOverlay.active {
  visibility: visible;
  pointer-events: all;
}

.labelChk {
  font-size: 14px;
}

.errorRegDiv {
  color: red;
  font-size: 16px;
}

.ratingsContainer {
  justify-content: space-between;
  gap: 2px !important;
  flex-wrap: wrap;
}

.ratingsContainer > .ratingBox {
  color: orange;
  background-color: black;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  transition: background-color 0.3s ease;
}

.ratingsContainer > .ratingBox > span {
  font-family: 'Comic Sans', sans-serif;
  font-size: 10px;
  padding: 5px;
  width: 100%;
  height: 100%;
  text-align: center;
}

.ratingBox > button {
  width: 100%;
  box-sizing: border-box;
  font-family: 'Comic Sans', sans-serif;
  font-size: 16px;
  border: 1px solid black;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
}

.blackRate {
  background-color: black !important;
  color: orange !important;
}

.crimsonRate span{
  color: crimson !important;
  
}
.crimsonRate img{
  filter: brightness(0) saturate(100%) invert(15%) sepia(99%) saturate(4124%) hue-rotate(340deg) brightness(89%) contrast(95%);
}
.redRate span{
  color: #ff0000 !important;
  
}
.redRate img{
  filter: brightness(0) saturate(100%) invert(34%) sepia(100%) saturate(7469%) hue-rotate(356deg) brightness(105%) contrast(117%);
}
.lightgreenRate span{
  color: #9bfa20 !important;
  
}
.lightgreenRate img{
  filter: brightness(0) saturate(100%) invert(87%) sepia(55%) saturate(1096%) hue-rotate(31deg) brightness(115%) contrast(96%);
}
.orangeRate span{
  color: orange !important;
  
}
.orangeRate img{
  filter: brightness(0) saturate(100%) invert(70%) sepia(15%) saturate(7310%) hue-rotate(0deg) brightness(104%) contrast(102%);
}

.greenRate span{
  color: #32D583 !important;
}

.greenRate img{
  filter: brightness(0) saturate(100%) invert(67%) sepia(90%) saturate(348%) hue-rotate(92deg) brightness(87%) contrast(96%);
}
.blackRateBg{
  background-color: black !important;
}
.crimsonRateBg{
  background-color: crimson !important;
}
.redRateBg{
  background-color: #ff0000 !important;
}
.orangeRateBg{
  background-color: orange !important;
}
.lightgreenRateBg{
  background-color: #9bfa20 !important;
}
.greenRateBg{
  background-color: #32D583 !important;
}
.crimsonBtn,
.redBtn,
.orangeBtn,
.lightgreenBtn,
.greenBtn {
  color: white !important;
}

.crimsonBtn {
  background-color: crimson !important;
}

.redBtn {
  background-color: red !important;
}

.orangeBtn {
  background-color: orange !important;
}

.lightgreenBtn {
  background-color: lightgreen !important;
}

.greenBtn {
  background-color: #9bfa20 !important;
}

.commentContent {
  word-break: break-word;
  font-family: 'Comic Sans', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  width: 100%;
  box-sizing: border-box;
  font-weight: 700;
}

.showMoreComments {
  background-color: #2c3ec4;
  color: white;
  font-family: 'Comic Sans', sans-serif;
  font-weight: 700;
  border: 2px solid #2c3ec4;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  padding: 5px;
}

.blockUserButton {
  color: red !important;
}

.reasonInput {
  width: 100%;
  resize: none;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  outline: none;
  border-radius: 6px;
}

.giftButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  outline: none;
  border: 0;
  height: 25px;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}

.giftButton svg {
  height: 1.5rem;
  width: 1.5em;
  color: #9bfa20;
}

.giftButtonRed svg {
  color: #ff0000;
}

.giftModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.giftModal button {
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: none;
  border: 0;
  background-color: transparent;
  gap: 4px;
  cursor: pointer;
}

.giftModal p {
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
}

.showAnswersContainer {
  display: flex;
  width: 100%;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
}

.showAnswersContainer button {
  flex: 1;
  border: none;
  border-radius: 0.5rem;
  padding: 8px 6px;
  font-size: 14px;
  border: 1px solid #ffffff29;
}

.showAnswersContainer button.showPrevAnswers {
  color: white;
  background-color: #454a59;
}

/* .showAnswersContainer.hideAnswers {
  color: white;
  background-color: #f9422a;
  margin-bottom: 4px;
}

.showAnswersContainer.noMoreAnswers {
  color: white;
  background-color: #d51131;
  margin-bottom: 4px;
  cursor: default;
} */

.showAnswersContainer button.showNextAnswers {
  color: #d6ff00;
  background-color: #ffffff29;
}

.showAnswersContainer.beFirstToComment {
  color: black;
  background-color: #42fb1e;
  justify-content: center;
}

.showAnswersContainer.beFirstToComment div {
  width: 40px;
}

.showAnswersContainer.beFirstToComment span {
  flex: none;
}

.allCommentsLoaderWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.allCommentsLoaderWrapper div {
  align-items: flex-start !important;
  padding-top: 2rem;
}

.pinnedComment {
  font-family: 'Comic Sans', sans-serif;
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
  background: linear-gradient(to right, #e30000, #770303);
  cursor: default;
  font-weight: 700;
}

.pinnedComment .betkoMessageTitle {
  color: #ffc000;
}

.pinnedComment .betkoMessageText {
  color: white;
}

/* REDESIGN */

.answersContainer {
  border: 1px solid #212631;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  overflow: hidden;
  background-color: #454a59;
  border-top: 0;
}

.textAreaWrapper {
  display: flex;
  align-items: center;
  background-color: #212631;
  border-radius: 12px;
  padding: 0 1rem;
  min-height: 56px;
  cursor: text;
}

.messageIcon,
.sendIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.messageIcon {
  padding-right: 12px;
  border-right: 1px solid #d0d5dd;
  height: 32px;
}

.messageIcon img,
.sendIcon img {
  width: 20px;
  height: 20px;
}

.sendIcon {
  background-color: #ffffff;
  border: 1px solid #d0d5dd;
  height: 32px;
  width: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.sendIcon:hover {
  background-color: #f0f0f0;
}

.commentInputContainer {
  background-color: #454a59;
  border: 1px solid #212631;
  padding: 10px;
  padding-top: 0;
  border-radius: 0.5rem;
}

.commentInputContainer span {
  color: var(--a-color);
}

.commentInputContainer::placeholder {
  color: white;
  opacity: 0.7;
}

.commentInputContainer:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.commentInputContainer textarea {
  width: 100% !important;
  resize: none;
  padding-inline: 12px;
  padding-top: 16px;
  display: flex;
  align-items: center;
  border: none;
  background-color: #353945;
  outline: none;
  color: white;
  font-size: 14px;
  vertical-align: middle;
}

.commentInputContainer .textAreaWrapper {
  background-color: #353945;
  min-height: 68px;
  border: 1px solid #212631;
}

.commentInputContainer textarea::placeholder {
  color: white;
}

.mobileOnlyAds {
  display: none;
}

.newCommentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.newCommentContainer button {
  width: 100%;
  border: 1px solid #b8db00;
  background-color: #d6ff00;
  border-radius: 0.5rem;
  font-size: 14px;
  text-align: center;
  padding: 20px 0;
}

.newCommentContainer .sendIcon:hover {
  background-color: #d6ff00;
}

.newCommentContainer button img {
  filter: invert(1);
  margin-right: 10px;
}

@media (max-width: 768px) {
  .mobileOnlyAds {
    display: block;
  }
}
