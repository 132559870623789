.table {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 3px 0px #1018281a;
}

.tableItem {
  border: 1px solid #212631;
}

.tableHeader,
.tableItem {
  color: white;
  display: grid;
  grid-template-columns: 1fr 4fr 2fr 4fr 3fr;
  align-items: center;
  padding: 1rem;
}

.tableItem > *:not(.userInfo) {
  display: flex;
  justify-content: center;
}

.tableHeader,
.awardDescriptionHeader {
  font-size: 12px;
}

.tableHeader span:not(.tableHeader span:nth-child(2)) {
  display: flex;
  justify-content: center;
}

.tableHeader span:nth-child(2) {
  margin-left: 4rem;
}

.tableItem:nth-child(odd) {
  background-color: #353945;
}

.tableItem:nth-child(even) {
  background-color: #41434b;
}

.tableItem > img {
  width: 50px;
}

.awardDescription {
  color: var(--a-color);
  text-decoration: underline;
}

.awardDescription:hover {
  cursor: pointer;
}

.userInfo {
  display: flex;
  gap: 15px;
  overflow: hidden;
}

.userInfo span {
  overflow: scroll;
}

@media (max-width: 768px) {
  .tableItem,
  .tableHeader {
    grid-template-columns: 1fr 4fr 2fr;
  }

  .awardDescription,
  .awardDescriptionHeader,
  .competitionPeriod {
    display: none !important;
  }

  .tableItem {
    font-size: 14px;
  }

  .userInfo {
    padding-left: 5px;
    gap: 5px;
  }
}
