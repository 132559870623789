.container {
  overflow: auto;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
}
.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.headerContainer {
  width: 100%;
  border-bottom: 2px solid #d6ff00;
  padding: 10px;
  text-align: center;
  margin-top: 24px;
}
.headerContainer > h1 {
  color: #d6ff00;
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 28px;
}
.userInfoForm {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  background-color: #353945;
  border: 1px solid #212631;
  padding: 16px;
  border-radius: 8px;
  overflow: auto;
  margin-left: 16px;
  margin-right: 16px;
}
.infoText {
  color: #d6ff00;
  font-weight: 500;
  font-size: 16px;
  text-align: start;
}
.formItem > label {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
}
.input {
  background-color: #262c39 !important;
  border-radius: 8px !important;
  color: #ffffff;
  outline: none;
  margin-top: 8px;
  border: none;
}
.input:focus {
  border: 1px solid #b8db00 !important;
}
.input::placeholder {
  color: #ffffffad !important;
}
.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.choseContainer > p {
  color: #d6ff00;
  font-weight: 500;
  font-size: 16px;
}
.buttonContainer {
  margin-top: 16px;
}
.buttonContainer > button {
  background-color: #ffffff29;
  border: 1px solid #d0d5dd;
  padding: 8px 16px;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  border-radius: 8px;
}
.buttonContainer > button > a {
  text-decoration: none;
  color: #fff;
}
.usefulInofrmationContainer {
  background-color: #262c39;
  border: 1px solid #d6ff00;
  padding: 12px;
  border-radius: 8px;
  margin-top: 24px;
}
.usefulInofrmationContainer > p {
  color: #d6ff00;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 8px;
}
.infoContainer > div > p > img {
  width: 13px;
  height: 13px;
}
.infoContainer > div > p {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
}
.cartTitle {
  display: flex;
  flex-direction: column;
}
.cartTitle > span {
  color: #d6ff00;
  font-weight: 500;
  font-size: 16px;
}
.cartTitle > button {
  background-color: #ffffff29;
  border: 1px solid #d0d5dd;
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  margin-top: 16px;
}
.divider {
  border: 1px solid #212631;
  margin-top: 16px;
}
.totalSum {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}
.totalSum > p {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
}
.totalSum > span {
  background-image: radial-gradient(
    80.46% 139.58% at 23.26% -4.17%,
    #f1f0f3 7.78%,
    #737175 39.15%
  );
  font-size: 28px;
  font-weight: 700;
  color: transparent;
  background-clip: text;
}
.continuePaymentButton {
  padding: 12px 24px;
  background-color: #d6ff00;
  border: 1px solid #b8db00;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  margin-top: 16px;
}
.paymentImg {
  margin-top: 16px;
}
.desktopHolder {
  padding: 16px;
  background-color: #353945;
  margin-left: 16px;
  margin-right: 16px;
  border: 1px solid #212631;
  border-radius: 8px;
}
.modalBody {
  width: 100%;
  border-radius: 12px;
}
.modalHeader {
  padding-bottom: 8px;
  border-bottom: 1px solid #d6ff00;
}
.modalHeader > h2 {
  color: #d6ff00;
  font-weight: 500;
  font-size: 18px;
}
.modalContent:first-child {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalContent {
  margin-top: 24px;
}
.modalContent > p {
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 24px;
}
.cvcNumber {
  width: 500px !important;
  position: relative;
  right: 14px !important;
}
.modalContent > img {
  width: 312px;
}
@media (min-width: 1024px) {
  .userInfoForm {
    width: 783px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .inputContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  .formItem {
    display: flex;
    flex-direction: column;
  }

  .input:focus {
    border-color: #b8db00 !important;
  }
  ::placeholder {
    color: #ffff;
  }
  .formItem:nth-child(odd) {
    grid-column: 2;
  }

  .formItem:nth-child(even) {
    grid-column: 1;
  }
  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .buttonContainer > button {
    width: 367px;
  }
  .contentWrapper {
    flex-direction: row;
    align-items: flex-start;
  }

  .userInfoForm {
    flex: 2;
  }

  .desktopHolder {
    flex: 1;
    max-width: 400px;
    position: sticky;
    top: 20px;
    height: fit-content;
    background-color: #353945;
    border: 1px solid #212631;
    padding: 16px 16px 16px 16px;
    border-radius: 8px;
    margin-top: 24px;
    margin-left: 0px;
  }
  .divider {
    margin-top: 16px;
  }
  .totalSum {
    margin-top: 16px;
  }
  .continuePaymentButton {
    margin-top: 16px;
    width: 100%;
  }
  .paymentImg {
    margin-top: 16px;
    padding: 0px;
  }
  .cvcNumber {
    width: 936px !important;
    right: 0px !important;
  }
}

@media (max-width: 768px) {
  #paymentForm {
    margin: 0;
  }
}
