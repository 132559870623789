.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  overflow-y: hidden;
  flex-wrap: wrap;
  scrollbar-width: none;
}

.container h1 {
  color: var(--a-color);
  border-bottom: 2px solid var(--a-color);
  border-top: 2px solid var(--a-color);
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
  margin-top: 24px;
  display: block;
}

.adsContainer {
  position: sticky;
  height: fit-content;
  top: 0;
  align-self: flex-start;
}

.postsPageContainer {
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  margin: 0 10px;
  height: 100%;
  flex: 1;
}

.postsAndCalculatorWrapper {
  display: flex;
  gap: 10px;
  position: relative;
}

.postsContainer {
  flex-grow: 1;
  min-width: 60%;
}

.sideImagesContainer {
  position: sticky;
  height: calc(100vh - 100px);
  top: 0;
  overflow: scroll;
}

@media screen and (max-width: 1280px) {
  .postsPageContainer {
    max-width: 100vw;
    padding: 0 10px;
  }
}

@media screen and (max-width: 767px) {
  .postsPageContainer {
    margin-left: 0;
    max-width: 100vw;
  }

  .postsContainer {
    margin-left: 10px;
  }
}

@media (max-width: 768px) {
  .postsPageContainer {
    padding: 0;
    margin: 0;
  }

  .container h1 {
    margin-top: 80px;
  }

  .postsAndCalculatorWrapper {
    display: block;
    margin-left: -10px;
    margin-right: 1px;
  }
}
