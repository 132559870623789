.singleComment {
  background-color: #353945;
  border: 1px solid #212631;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-radius: 12px;
  overflow: hidden;
}

.singleCommentInner {
  padding: 1rem;
  border-bottom: 1px solid #212631;
}

.highlighted {
  border: 3px solid #d6ff00 !important;
}

.adminCommentMessage {
  background-color: #2e3551;
  border: 1px solid #d6ff00;
  padding: 8px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.adminCommentMessage div img {
  height: 36px;
  aspect-ratio: 1/1;
}

.adminCommentMessageContent {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.adminCommentMessageContent .adminCommentText {
  font-size: 13px;
  font-weight: 400;
  color: #ffffff;
}

.adminCommentTitle {
  color: var(--a-color);
  font-weight: 600;
  font-size: 1rem;
}

.commentHeader {
  display: flex;
}

.userInfo {
  color: #ffffffad;
  font-size: 12px;
  margin-inline: 0.5rem;
  font-weight: 400;
}

.userRole {
  display: flex;
  align-items: center;
  gap: 2px;
}

.userName {
  color: var(--a-color);
  font-size: 1rem;
  line-height: 22px;
  font-weight: 500;
  word-break: break-word;
}

.userIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14px;
  width: 14px;
}

.commentHeaderBtns {
  display: flex;
  gap: 0.5rem;
  margin-left: auto;
  position: relative;
}

.commentHeaderBtns button {
  height: 24px;
  width: 24px;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  outline: none;
  justify-content: center;
  background-color: transparent;
  outline: none;
  border: none;
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  width: 72px;
  border: 1px solid transparent;
  border-radius: 5.76px;
  flex-shrink: 0;
  background-color: transparent;
  overflow: hidden;
}

.logoWrapper img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

/* COMMENT TIME */

.commentTimeAndEditWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;

  color: #ffffffad;
  font-size: 13px;
  font-weight: 400;
  margin-block: 1rem;
}

.commentEditButtonWrapper {
  display: flex;
  gap: 10px;
}

.commentEditButtonWrapper span:hover {
  text-decoration: underline;
}

.commentEditWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.commentEditWrapper textarea {
  border: none;
  width: 100%;
  color: white;
  outline: none;
  resize: none;
}

.commentEditSaveButtonWrapper {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.commentEditSaveButtonWrapper button {
  background-color: #d6ff00;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem;
  flex: 1;
}

.commentEditSaveButtonWrapper button:last-child {
  background-color: #2c2c3e;
  color: white;
}

/* COMMENT BODY */

.commentBody {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 1rem;
  user-select: text;
}

/* COMMENT FEEDBACK */

.commentFeedback {
  display: flex;
  justify-content: space-between;
}

.feedbackGroup {
  display: flex;
  gap: 20px;
}

.commentFeedback div div {
  height: 26px;
  /* width: 26px; */
  padding: 4px;
  border-radius: 4px;
}

.commentFeedback .feedbackLink img {
  filter: invert(1);
}

.commentFeedback .feedbackLink {
  padding: 3px;
  border-radius: 0.5rem;
}

.commentFeedback .feedbackLink:hover {
  background-color: #2c2c3e56;
}

.feedbackGroup .feedbackLike div {
  background-color: #32d583;
}

.feedbackGroup .feedbackDislike div {
  background-color: #f04438;
}

.feedbackGroup .feedbackRating div {
  background-color: #fec42d;
}

.commentFeedback div {
  display: flex;
  align-items: center;
  gap: 4px;
}

.commentFeedback div span {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.ratingPopupWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.starsWrapper {
  display: flex;
  gap: 2rem;
}

.ratingNumber {
  background: none;
  border: none;
  cursor: pointer;
}

.starIcon {
  width: 30px;
  height: 30px;
  transition: opacity 0.2s;
}

.confirmButton {
  background: white;
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
}

/* COMMENT MENU */

.optionsMenu {
  background-color: #262c39;
  border: 1px solid #212631;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 2rem;

  right: 0;
  width: fit-content;
  border-radius: 4px;
  padding: 0 8px;
  z-index: 1;
}

.optionsMenu li {
  list-style: none;
  width: 169px;
  display: flex;
  padding: 0.5rem 0;
  border-bottom: 1px solid #212631;
  border-radius: none;
}

.optionsMenu ul li:last-child {
  border: 0;
}

.optionsMenu li button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  width: 100%;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  border-radius: 0px;
}

.idLi {
  border: none;
}

.idLi button span {
  color: #ffffffad;
  font-size: 10px;
  border: none;
}

.optionsMenu li button:disabled span {
  color: #ffffffad;
  display: none;
}

.optionsMenu li button img {
  height: 20px;
  width: 20px;
}

/* COMMENT FEEDBACK OPTIONS */
.commentFeedbackOptions {
  display: flex;
  gap: 4px;
  margin-block: 20px;
  background-color: #212631;
  padding: 4px;
  border-radius: 8px;
}

.commentFeedbackOptions button {
  flex: 1;
  padding: 6px;
  border: none;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.commentFeedbackOptions button img {
  height: 20px;
  width: 20px;
  border: none;
  outline: none;
}

/* TODO: FOR LIKED  */

.alreadyLiked span {
  color: #32d583 !important;
}
.alreadyLiked img {
  filter: brightness(0) saturate(100%) invert(83%) sepia(59%) saturate(609%)
    hue-rotate(78deg) brightness(90%) contrast(85%);
}
.alreadyDisliked span {
  color: #f04438 !important;
}
.alreadyDisliked img {
  filter: brightness(0) saturate(100%) invert(32%) sepia(52%) saturate(2747%)
    hue-rotate(341deg) brightness(98%) contrast(91%);
}

/* COMMENT TYPE */

.commentLinkToPost {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.commentLinkToPost button {
  display: flex;
  background-color: var(--a-color);
  padding: 0.25rem 1rem;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  gap: 6px;
  justify-content: center;
  padding: 9px 1rem;
  border-radius: 0.5rem;
  border: 1px solid #b8db00;
}

.commentLinkToPost img {
  height: 20px;
  width: 20px;
}

.commentType {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #ffffffd1;
}

.commentFeedbackOptions button span {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: white;
  margin-left: 4px;
  display: none;
}

.commentInputContainer {
  background-color: #454a59;
  padding: 1rem;
}

.textAreaWrapper {
  display: flex;
  align-items: center;
  background-color: #212631;
  border-radius: 12px;
  padding: 12px;
  min-height: 56px;
  height: 56px;
}

.messageIcon,
.sendIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.messageIcon {
  padding-right: 12px;
  border-right: 1px solid #d0d5dd;
  height: 32px;
}

.messageIcon img,
.sendIcon img {
  width: 20px;
  height: 20px;
}

.sendIcon {
  background-color: #ffffff;
  border: 1px solid #d0d5dd;
  height: 32px;
  width: 40px;
  border-radius: 6px;
}
.commentInputContainer textarea {
  width: 100% !important;
  resize: none;
  padding-inline: 12px;
  padding-top: 16px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background-color: #212631;
  color: white;
  font-size: 14px;
  vertical-align: middle;
}

.commentInputContainer textarea::placeholder {
  color: white;
}

.showCommentsBtn {
  background-color: #ffffff29;
  color: var(--a-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 0.5rem;
  border: none;
  width: 100%;
  padding: 6px;
  height: 36px;
  margin-top: 1rem;
}

.withAnswersShown {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
}

@media (min-width: 1024px) {
  .commentLinkToPost {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .commentFeedbackOptions button span {
    display: inline;
  }
}

a {
  color: #d6ff00;
}

.pinnedAdminComment {
  cursor: pointer;
}

.showMoreButton {
  background-color: transparent;
  border: none;
  color: #d6ff00;
  padding-bottom: 0.5em;
}

.commentHeaderBtns .giftBtn > img {
  filter: brightness(0) saturate(100%) invert(31%) sepia(94%) saturate(3019%)
    hue-rotate(345deg) brightness(107%) contrast(88%);
}

.hasGift {
  filter: brightness(0) saturate(100%) invert(70%) sepia(88%) saturate(380%)
    hue-rotate(89deg) brightness(87%) contrast(90%) !important;
}
