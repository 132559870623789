.headerContainer {
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #d6ff00;
  border-top: 2px solid #d6ff00;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.headerContainer h1 {
  color: #d6ff00;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.1;
}

.content {
  color: #fff;
  line-height: 22px;
  margin: 0 10rem 0 10rem;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
}
.content > p {
  margin-top: 24px;
}

.content > img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-top: 24px;
}

@media (max-width: 1024px) {
  .content {
    margin: 0;
  }

  .content p {
    margin-left: 5px;
    margin-right: 5px;
    text-align: justify;
  }
}

@media (min-width: 1024px) {
  .mainContainer {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 32px;
    max-width: 1200px;
    margin: 0 auto;
  }
  .headerContainer {
    margin-top: 24px;
  }
  .sidebar {
    display: block;
  }

  .content {
    padding: 0;
    overflow: scroll;
  }
  .content > p {
    margin-top: 26px;
  }
}
