.videoContainer {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.video {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 200px;
  overflow: hidden;
  background-color: white;
  border: 2px solid #d6ff00;
  border-radius: 0.5rem;
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.controls {
  background-color: transparent;
  height: 20px;
  position: absolute;
  top: 0.3rem;
  right: 0.2rem;
  gap: 1px;
}

.controls img {
  width: 20px;
  aspect-ratio: 1;
}

.button {
  display: inline-flex;
  background: transparent;
  color: white;
  width: auto;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  overflow: hidden;
}
.button:hover {
  background: rgba(0, 0, 0, 0.8);
}

@media (max-width: 768px) {
  .controls {
    display: none;
  }
}
