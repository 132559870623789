.footer {
  width: 100%;
  display: flex;
  margin: 0 auto;
  height: 60px;
  color: #ffffff;
  position: relative;
  justify-content: center;
  z-index: 101;
  background-color: #2b3240;
  overflow: hidden;
}

.img {
  display: flex;
  max-width: 100%;
  width: auto;
  height: 60px;
  background-size: cover;
  background-repeat: round;
  font-size: 27px;
  object-fit: contain;
}

.askBetko {
  padding-right: 0.5rem;
}

@media (max-width: 768px) {
  .footer .img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .footer {
    overflow-y: hidden;
  }

  .askBetko {
    display: none;
  }
}
