.container {
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow: scroll;
  width: 100%;
  scrollbar-width: none;
}

.infoBlockContainer {
  width: 100%;
  max-width: 100%;
}

.container::-webkit-scrollbar {
  width: 15px !important;
  height: 10px !important;
}

.container::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 4px !important;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.container::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 4px !important;
}

.adsContainer {
  position: sticky;
  height: fit-content;
  top: 0;
  align-self: flex-start;
}

.postsPageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  flex: 1;
}

.pageTitleContainer {
  width: 100%;
  position: relative;
}

.pageTitle {
  color: var(--a-color);
  border-bottom: 2px solid var(--a-color);
  border-top: 2px solid var(--a-color);
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  margin-block: 1.5rem;
  display: block;
  flex-grow: 1;
}

.backButton {
  display: none;
}

.postsAndCalculatorWrapper {
  display: flex;
  gap: 10px;
  width: 100%;
  position: relative;
}

.postsContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  flex: 1;
}

.sideImagesContainer {
  position: sticky;
  height: calc(100vh - 100px);
  top: 0;
  overflow: scroll;
}

@media (min-width: 1024px) {
  .backButton {
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    cursor: pointer;
    position: absolute;
    background-color: #ffffff29;
    color: #fff;
    border: 1px solid #d0d5dd;
    padding: 8px 16px 8px 12px;
    margin-bottom: 6px;
    font-weight: 500;
    margin-top: 2.25rem;
  }
  .backButton > img {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 768px) {
  

  .postsAndCalculatorWrapper {
    gap: 0px;
  }
}
