.deniedPageContianer * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.deniedPageContianer  {
  display: flex;
  flex-direction: column;
  text-align: start;
  justify-content: start;
  background-image:linear-gradient(to top left, #111219, #40445e); 
  border: 1px solid #212631;
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  max-width: 526px;
  margin: 0 auto;
  margin-top: 40px;
}

.iconWrapper img{
  width: 44px;
  height: 44px;

}

.deniedPageContianer h2 {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 600;
  margin-top: 24px;
}

.deniedPageContianer p {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 18px;
  margin-top: 24px;
}

.deniedPageContianer button {
  background-color: #D92D20;
  padding: 12px 24px;
  border-radius: 6px;
  width: 100%;
  margin-top: 24px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
}
