.container {
  margin: 2rem;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-height: 100vh;
}

.container > h1 {
  border-bottom: 1px solid white;
}

.btn {
  width: 160px;
  padding: 14px;
  margin: 10px 0;
  margin: 10px 0;
  font-size: 18px;
  color: #d6ff00;
  text-align: center;
  background-color: black;
  border: 1px solid #d6ff00;
  border-radius: 12px;
  cursor: pointer;
}

.container h2,
.container h1 {
  margin-bottom: 1rem;
}

.container > div {
  margin-bottom: 1rem;
  border-bottom: 1px solid white;
}

.bottom {
  margin-bottom: 3rem;
}

.hiddenInput {
  display: none;
}

.selectedAdsContainer,
.allAdsContainer {
  display: flex;
  flex-wrap: wrap;
  width: inherit;
  justify-content: center;
  gap: 5rem;
}

.allAdsContainer {
  gap: 1rem;
}

.adsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.disabledAd {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.adContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 200px;
  width: 200px;
  overflow: hidden;
  background-color: transparent;
  border: 2px solid #d6ff00;
  border-radius: 0.5rem;
  cursor: pointer;
}

.adContainer p {
  max-width: 15ch;
}

.adContainer.active {
  border: 2px solid aqua;
}

.adContainerMargin {
  margin-bottom: 2rem;
}

.uploadContainer {
  background-color: #303030;
  border-radius: 0.5rem;
  border: 1px solid #d6ff00;
  padding: 1rem;
  margin-bottom: 1rem;
}

.uploadContainer .uploadButton {
  margin-bottom: 0;
}

.uploadSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.inputField {
  width: 300px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #d6ff00;
  border-radius: 8px;
  background-color: black;
  color: white;
}

.adsWrapper > .inputField {
  width: 200px !important;
  margin-top: 0.5rem;
}

.fileInput {
  display: none;
}

.uploadButton {
  width: 200px;
  padding: 12px;
  font-size: 16px;
  color: black;
  background-color: #d6ff00;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 1rem;
}

.uploadButton:hover {
  background-color: #b3cc00;
}

.uploadButton:disabled {
  cursor: default;
  pointer-events: none;
  opacity: 0.6;
}

.uploadSection label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  color: white;
  cursor: pointer;
}

.uploadSection label input {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.innerContainer {
  display: flex;
  width: 100%;
}

.innerContainer .btn {
  margin: 0;
}

.saveChangesContainer {
  background-color: transparent;
  position: absolute;
  top: 80px;
  right: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;
  box-sizing: border-box;
}

.paginationWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.deleteButtonWrapper {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.deleteButtonWrapper button {
  background-color: #d6ff00;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem;
  flex: 1;
}

.deleteButtonWrapper button:last-child {
  background-color: #2c2c3e;
  color: white;
}

.arrowContainer {
  width: 100%;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
}

.arrowContainer button {
  width: 60px;
  border: 2px solid var(--a-color);
  background-color: transparent;
  border-radius: 8px 8px 0px 0px;
  border-bottom: none;
  font-size: 20px;
  font-weight: 800;
  color: var(--a-color);
}

.arrowContainer button:disabled {
  color: #d4ff002f;
}

@media (max-width: 800px) {
  .innerContainer {
    flex-direction: column;
  }
}
