.commentAdsWrapper .wrapper {
  width: 300px;
  /* height: calc(100vh - 125px); */
  /* height: calc(100vh - 175px); */
}

.wrapper {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.wrapperMobile {
  display: flex !important;
  overflow: hidden;
}

.adItem {
  min-width: 100%;
  width: 100%;
  /* padding: 5px; */
  box-sizing: border-box;
}

@media (max-width: 1280px) {
  .wrapper {
    display: none;
  }

  .commentAdsWrapper .wrapper {
    display: flex;
  }
}

@media (max-width: 960px) {
  .commentAdsWrapper .wrapper {
    display: none;
  }
}

.video {
  width: 100% !important;
  height: auto !important;
  border: none !important;
  border-radius: 0 !important;
}
