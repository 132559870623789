.tipsterMatchContainer {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  gap: 10px;
}

.tipsterMatchContainer .tip {
  background-color: #2b3240;
  border: 1px solid #212631;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.tipsterMatchContainer .tip:disabled {
  color: #ffffff7c;
}

.isSelected {
  border: 1px solid var(--a-color) !important;
}

.win {
  border: 1px solid #4dfa61 !important;
  color: #4dfa61 !important;
}

.fail {
  border: 1px solid #ff336b !important;
  color: #ff336b !important;
}

.tipsterMatchContainer * {
  border-radius: 8px;
}

.matchInfo {
  display: grid;
  grid-template-columns: 70px auto;
  grid-template-rows: auto auto auto;
  gap: 10px;
}

.matchInfo .matchLeague {
  grid-column: span 2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  background-color: #2e3551;
  border: 1px solid #212631;
  padding: 6px;
  color: white;
}

.matchInfo .matchLeague img {
  width: 24px;
}

.matchInfo .matchTime {
  grid-row: span 2;
  background-color: #cabb90;
  border: 1px solid #212631;
  color: #212631;
  display: flex;
  justify-content: center;
  align-items: center;
}

.matchInfo .matchEnded {
  color: #5b0000;
}

.matchInfo .matchTeam {
  background-color: #2b3240;
  border: 1px solid #212631;
  color: white;
  padding: 4px 8px;
}
