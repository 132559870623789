.categoriesSidebar {
  width: 100%;
  height: 100%;
  max-height: 340px;
  overflow: auto;
  padding: 0px !important;
  background-color: #353945;
  border-radius: 12px;
}
.categoriesSidebar > h3 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  padding: 6px 8px;
}
.categoriesSidebar > ul {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.categoriesSidebar ul > li:first-child {
  background-color: #262c39;
  padding: 6px 8px;
  border-radius: 8px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  margin-top: 6px;
}
.categoriesSidebar li {
  background-color: #262c39;
  padding: 6px 8px !important;
  border-radius: 8px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
}
.selectedCategory {
  border: 1px solid #d6ff00;
  color: #d6ff00 !important;
}
@media (min-width: 1024px) {
  .categoriesSidebar{
    max-width: 100%;
    max-width: 200px;
    border: 1px solid #212631;
    padding: 8px  !important;

  }
}