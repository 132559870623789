.optionsAndMarketing {
  background-color: #353945;
  border: 1px solid #212631;
  padding: 1rem 10px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.postTitle,
.postDescription {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  color: #d6ff00;
  text-align: center;
}

.postDescription {
  font-size: 14px;
}

.marketingMediaWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.featureVideoDiv,
.featureImage,
.marketingImage,
.marketingMediaWrapper img {
  border: 2px solid var(--a-color);
  border-radius: 0.5rem;
  aspect-ratio: 1.26;
  width: 100%;
  max-width: 337px;
}

.postOptions {
  display: flex;
  background-color: #262c39;
  padding: 4px;
  padding-inline: 1rem;
  border: 1px solid #212631;
  justify-content: space-between;
  border-radius: 0.5rem;
}

.postOptions button {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  color: #ffffff;
  align-items: center;
  padding-block: 6px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.postOptions button img {
  height: 20px;
  width: 20px;
}

.postOptions button:nth-of-type(1) img {
  transform: rotate(270deg);
}

.postOptions button:nth-of-type(4) img {
  transform: rotate(180deg);
}

.postOptions button:nth-of-type(5) img {
  transform: rotate(90deg);
}

.postOptions button span {
  display: none;
}

.commentCounterContainer {
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  text-align: center;
}

.commentCounter {
  font-size: 14px;
  font-weight: 500;
  color: #ffffffd1;
  width: 100%;
}

.counterLabel {
  color: #ffffffd1;
}

.leaveCommentContainer {
  display: flex;
  align-items: center;
}

.leaveCommentContainer > p {
  color: white;
  flex: 2;
  padding-top: 1rem;
}

.leaveCommentContainer > button {
  flex: 1.5;
}

.leaveCommentButton {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #d6ff00;
  color: #252f3b;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #b8db00;
  padding: 4px 16px;
  border-radius: 8px;
  width: 100%;
  justify-content: center;
  margin-top: 16px;
  height: 40px;
}

.leaveCommentButton > img {
  width: 20px;
  height: 20px;
}

.emailNotificationsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  gap: 16px;
  margin-top: 16px;
  width: 100%;
  justify-content: start;
  box-sizing: border-box;
}

.emailNotifications {
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
}

.emailNotifications > input[type='checkbox'] {
  display: block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  color: #000;
}

.contentRow {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contentRow div {
  justify-content: center;
  text-align: center;
}

.fixedCommentButtonContainer .leaveCommentButton {
  display: none;
}

@media (max-width: 768px) {
  .leaveCommentContainer > p {
    display: none;
  }

  .mobileButtonWrapper {
    width: 100vw;
    position: absolute;
    top: -210px;
    left: -21px;
    background-color: var(--p-color);
    height: 3rem;
  }

  .fixedCommentButtonContainer .leaveCommentButton {
    display: flex;
  }

  .fixedCommentButtonContainer {
    top: 60px;
    position: fixed;
    overflow: hidden;
    left: 0;
    background-color: var(--p-color);
    width: 100%;
    padding: 0 10px 10px 10px;
    z-index: 1;
    transition: top 0.2s ease-in-out;
  }

  .leaveCommentContainer {
    display: none;
  }
}
