.cardPlaceholder {
  height: 220px;
  width: 100%;
  max-width: 166px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  background-image: linear-gradient(to top, #111219, #40445e);
  overflow: hidden;
  border: 1px solid;
  border-image: linear-gradient(#ffffff29, #111219);
  position: relative;
  cursor: pointer;
}
.timer{
  background-image: linear-gradient(to top left, #111219, #40445e);
  color: #d6ff00;
  display: flex;
  align-items: center;
  gap: 6px;
  height: 24px;
  width: 100%;
  max-width: 105px !important;
  position: absolute;
  right: 60px;
  padding: 8px !important;
}
.timer > img {
  width: 18px !important;
  height: 18px !important;
}
.imageWrapper {
  width: 100% !important;
  min-height: 119px;
}
.imageWrapper > img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}
.cardTitle {
  font-weight: 500;
  font-size: 14px;
  margin-top: 8px !important;
  color: #ffffffd1;
  text-align: center;
}
.cardPrice {
  font-size: 18px;
  font-weight: 700;
  background-image: linear-gradient(#f1f0f3, #737175);
  color: transparent;
  background-clip: text;
}
.learnMore {
  background-color: #d6ff00;
  border: 1px solid #b8db00;
  color: #252f3b;
  font-weight: 500;
  font-size: 14px;
  padding: 8px 24px !important;
  border-radius: 6px;
  margin-top: 12px !important;
}
@media (min-width: 1024px) {
  .cardPlaceholder {
    height: 260px;
    max-width: 196px;
  }
  .timer{
    right: 97px;
  }
  .imageWrapper{
    max-height: 157px !important;
  }
}
