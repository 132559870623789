.mainCenter {
  display: flex;

  flex-direction: column;
  height: 100%;
  text-align: left;
  overflow: auto;
}
.mainCenter > h1 {
  margin-top: 24px;
  text-align: center;
  border-top: 2px solid #d6ff00;
  border-bottom: 2px solid #d6ff00;
  color: #d6ff00;
  font-weight: 600;
  font-size: 28px;
  padding: 10px;
}
.mainCenter > p {
  margin-top: 24px;
  padding:0px 16px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
}
.mainCenter > img {
  margin-top: 15px;
  width: 600px;
  display: flex;
  border: 10px solid black;
  border-radius: 12px;
}
