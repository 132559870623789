.singleProductCard {
  width: 100%;
  max-width: 166px;
  height: 100%;
  cursor: pointer;
  border: 1px solid #ffffff29;
  border-radius: 12px;
  background: linear-gradient(317.13deg, #111219 10%, #40445e 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}
.cardHeader > img{
  width: 100% !important;
  height:auto;
  border-radius: 8px !important;
  object-fit: cover;
}
.cardBody{
  text-align: center;
}
.timer{
  background-image: linear-gradient(to top left, #111219, #40445e);
  color: #d6ff00;
  display: flex;
  align-items: center;
  gap: 6px;
  height: 24px;
  width: 100%;
  max-width: 105px !important;
  position: absolute;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 8px !important;
}
.cardBody > h3 {
  color: #d6ff00;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  text-wrap: nowrap;
}
.cardBody > h2 {
  background: radial-gradient(
    80.46% 139.58% at 23.26% -4.17%,
    #f1f0f3 7.78%,
    #737175 39.15%
  );
  color: transparent;
  background-clip: text;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  text-wrap: nowrap;
  margin-top: 6px;
}
.buyButton{
  background-color: #d6ff00;
  border: 1px solid #b8db00;
  color: #252f3b;
  font-weight: 500;
  font-size: 14px;
  padding: 4px !important;
  border-radius: 6px;
  margin-top: 6px !important;
  width: 100%;
  margin-bottom: 6px !important;
  text-wrap: nowrap;
}


@media (min-width: 1024px) {
  .singleProductCard {
    max-width: 196px;
  }
  .cardHeader > h2 {
    font-size: 32px;
  }
  .cardInfo {
    font-size: 14px;
  }
  .buyButton{
    padding: 8px 16px !important;
  }
}
